//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useEffect} from 'react';
//import { SaleContext } from '../Contexts/SaleContext';
import '../style/Shopping-cart.css';
import CartProducts from './CartProducts';
import { actions } from '../tools';
import { LocaleContext } from '../Contexts/LocaleContext';
import { useHistory } from 'react-router-dom';


const ShoppingCart = () => {
    //const { state, dispatch } = useContext(SaleContext);
    const app = useContext(LocaleContext);
    const history = useHistory();
    useEffect(() => {
        if (app?.data?.Sale?.ProductCount && app.data.Sale?.ProductCount > 0 && app.data.Sale.Products?.length === 0) {
            //dispatch({ type: actions.SET_PRODUCTS, payLoad: undefined });
        }
    }, [app?.data?.Sale?.ProductCount]);

    return <section className="clsInsidePage">
        <div className="clsPageAlign">
            <form className="clsShoppingCart">
                <h1>{app!.localized("ShoppingCart")}</h1>
                <CartProducts />
                <button style={{ borderRadius: 2, background: '#4c1518', color: 'white', padding: '5px', margin: '20px' }}
                    onClick={e => {
                        e.preventDefault();
                        history.push('/Purchase')
                    }}>{app?.localized('Checkout')}</button>
            </form>
        </div>

    </section>
}

export default ShoppingCart
