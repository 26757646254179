//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import '../../style/product.css'
import { currencyFormat, actions } from '../../tools'
import PackagesList from './PackagesList';
//import BrandBox from '../Brands/BrandBox';
//import { SaleContext } from '../../Contexts/SaleContext';
import * as m from '../../Declarations';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { serverUrlGlobal } from '../../tools';
import BrandBox, { BrandImage, DisplayImage, ProductAdmin, ProductImage, RecordImage, VecIcon } from '../../misc';
import LiteCart from '../LiteCart';
import App from '../../App';

const Brand = () => {
    let { id: BrandId } = useParams<{ id: string }>();
    //const { dispatch, state } = useContext(SaleContext)!;
    const ctx = useContext(LocaleContext)!;

    const [brand, setBrand] = useState<m.MiniBrand>();
    const [products, setProducts] = useState<m.MiniProduct[]>();
    const [packages, setPackages] = useState<m.MiniProduct[]>();
    const [additionProducts, setAdditionProducts] = useState<m.MiniBrand[]>();

    const [selectedParent, setSelectedParent] = useState<m.MiniProduct>();
    const [selectedPackages, setSelectedPackages] = useState<m.MiniProduct[]>();
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalListPrice, setTotalListPrice] = useState(0);
    const [showLiteCart, setShowLiteCart] = useState(false);

    useEffect(() => {
        const total = (selectedPackages && [...selectedPackages, selectedParent].reduce((a, b) => a + b?.SiteRealPrice!, 0)) || 0
        const totalListPrice = (selectedPackages && [...selectedPackages, selectedParent].reduce((a, b) => a + b?.SiteListPrice!, 0)) || 0
        setTotalPrice(total);
        setTotalListPrice(totalListPrice);
    }, [selectedPackages, selectedParent])

    useEffect(() => {
        if (brand) getAdditionalProducts();
    }, [brand]);

    useEffect(() => {
        if (products?.length === 1)
            onParentChange(products![0])
    }, [products]);

    useEffect(() => {
        getProducts();
        getBrand();
        getPackages();
    }, [BrandId, ctx.data?.Language]);

    const getBrand = async () => {
        const response = await m.LocalClient.Public.Brand(+BrandId)
        setBrand(response);
    }
    const getProducts = async () => {
        var data = await m.LocalClient.Public.Products(+BrandId);
        setProducts(data);
    }
    const getPackages = async () => {
        //const response = await axios.get(serverUrl(`Packages/${BrandId}`));
        const response = await m.LocalClient.Public.Packages(+BrandId)
        setPackages(response);
    }

    const getAdditionalProducts = async () => {
        if (brand) {
            const response = await m.LocalClient.Public.Brands(brand.CategoryID);// await axios.get(serverUrl(`Brands?CategoryID=${brand.CategoryID}`));
            setAdditionProducts(response);
        }
    }

    const onParentChange = (product: m.MiniProduct) => {
        setSelectedParent(product);
        setSelectedPackages([]);
    }

    const onPackageChange = (event: any, product: m.MiniProduct) => {
        const { checked } = event.target;
        let newArray;
        if (checked) {
            newArray = [...selectedPackages!, product];
        } else {
            newArray = selectedPackages!.filter((x) => x.ID !== product.ID);
        }
        setSelectedPackages(newArray)
    }



    const [selectedTab, setselectedTab] = useState(0);
    const displayStyle = {
        display: 'block'
    }
    const hideStyle = {
        display: 'none',
    }
    const HideLiteCart = () => {
        setShowLiteCart(false);
    }

    var selectedIds = selectedPackages && selectedPackages.map(x => x.ID);
    if (selectedParent) selectedIds?.push(selectedParent.ID)
    //var keys = Array(4).keys();
    return (<section className="clsProductSaction clsInsidePage" >
        {showLiteCart && <LiteCart parent={selectedParent} packages={selectedPackages} onCancel={HideLiteCart} />}
        <div className="clsPageAlign">
            <div style={{ display: 'flex', gap: 30 }}>
                <div className="clsItemDetails" >
                    <h1>{brand?.Name}</h1>
                    <form>
                        <article className="clsText">
                            <p>{brand?.SubTitle}{'  '}
                                <a href="#clsItemInformation" className="scroll">{ctx.localized("MoreDetails")}
                                    <span>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </a>
                            </p>
                            {products && products.map(p => {
                                var checked = p.ID === selectedParent?.ID
                                return <div className="clsProductOption" key={p.ID} style={{ justifyContent: 'center' }}>

                                    <div style={{ color: '#b9792a', fontSize: '24px', fontWeight: 900, gap: 20, display: 'flex' }}>
                                        <ProductAdmin {...p} />
                                        <input id={p.ID.toString()} name="question" type="radio" className="clsFirst" value="sel" checked={checked} />
                                        <label htmlFor={p.ID.toString()} className="clsRadioInput" onClick={() => onParentChange(p)} />
                                        <ProductImage id={p.ID} name={p.Name!} style={{ width: 60, height: "100%" }} />
                                        <label htmlFor={p.ID.toString()} onClick={() => onParentChange(p)} >{p.ExtendedName}</label>
                                        <span>{currencyFormat(p.SiteRealPrice)}</span>
                                        <p className="clsLabelDetails">{p.Brief}</p></div>
                                    {selectedParent && selectedParent.ID === p.ID && <PackagesList packages={packages} onClick={onPackageChange} />}

                                </div>

                            })}
                            <div className="clsItemTotal">
                                <div className="clsTotalPrice">מחיר מבצע:<br /><span>{currencyFormat(totalPrice)}</span></div>
                                <div className="clsTotalPriceBefor">מחיר רגיל:<br /><span>{currencyFormat(totalListPrice)}</span></div>
                                <button type="button"
                                    onClick={async () => {

                                        //dispatch({ type: actions.SET_LOADING, payLoad: true });
                                        const response = await m.LocalClient.Public.AddProducts(selectedIds!);//  axios.post(serverUrl('AddProducts'), { IDS: action.payLoad });
                                        ctx.setSale(response)
                                        // dispatch({ type: actions.SET_TOTALS, payLoad: response });
                                        //dispatch({ type: actions.SET_LOADING, payLoad: false });
                                        setShowLiteCart(true);
                                        window.scrollTo({ top: 0, behavior: 'smooth' })
                                    }}
                                >{ctx.localized("AddToCart")}
                                    {/* {state?.loading && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '-12px', marginRight: '15px', color: 'white' }} />} */}
                                </button>
                            </div>
                        </article>
                    </form>
                </div>


                <div className="clsItemImages" style={{ width: "100%" }}>
                    {brand && <BrandImage id={brand.ID} name={brand.Name!} style={{ width: "100%" }} />}
                    {/* {brand && brand.Displays?.map(x => <div className="clsSubImage" style={{ background: "url(" + serverUrlGlobal(`GetDoc?Type=Display&ID=${x.ID}`) + ") no-repeat center / contain" }}></div>)} */}
                    {brand && brand.Displays?.map(x => <DisplayImage id={x.ID} name={x.Name!} style={{ maxHeight: 150 }} />)}
                </div>
            </div>

            <div className="clsItemInformation" id='clsItemInformation'>
                <div>
                    <nav>
                        <ul>
                            {['MoreInfo', 'FullDescription', 'BookList'].map((x, i) => <li key={i} className={selectedTab === i ? 'clsCelected' : ''} onClick={() => setselectedTab(i)}>{ctx.localized(x)}</li>)}
                        </ul>
                    </nav>
                    <div className="clsExtraInfo   " style={selectedTab === 0 ? displayStyle : hideStyle} >
                        <p>{brand?.Description}</p>
                    </div>
                    <div className="clsExtraInfo  " style={selectedTab === 1 ? displayStyle : hideStyle}>
                        <p>{brand?.Specifications}</p>
                    </div>
                    <div className="clsExtraInfo" style={selectedTab === 2 ? displayStyle : hideStyle}>
                        <p className="clsText">
                            מאגר 'אוצר החכמה' מכיל בתוכו גם ספרים מהוצאות גדולות וידועות כגון: מכון ירושלים, מוסד הרב קוק, ספרי יד הרב נסים, מכון מש"ה לחקר הרמב"ם (הרב קאפ"ח), מכון התורה והארץ, ספרי וגשל , מכון פישל, משניות קהתי, תורה שלמה וכל ספרי המכון, מכון הכתב, אהבת שלום, ספרי הרב אבינר ועוד.
                        </p>
                        <div className="clsText clsBold">
                            <ul>{brand && brand.Displays && brand.Displays.map((x) => {
                                return <li>{x.Name}<a href={serverUrlGlobal(`GetDoc?Type=Display&Column=FileBytes&ID=${x.ID}&Download=True`)}><VecIcon name={x.FileType || ""} /></a></li>
                            })}</ul>
                        </div>
                    </div>
                </div>

            </div>
            <div className="clsAdditionProducts">
                <h2>{ctx.localized('AdditionalProductsWhichMightInterestYou')} </h2>
                <div style={{ display: 'flex' }}>
                    {additionProducts?.slice(0, 4).map((p) => <BrandBox key={p.ID} {...p} />)}


                </div>
            </div>
        </div>

    </section>);
}

export default Brand;