//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useContext } from 'react'
//import axios from 'axios';
//import { serverUrl } from '../../tools';
import { Link } from 'react-router-dom';
import * as m from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';
interface personalInfoProps {
    person?: m.MiniPerson,
    handleChange: (name: string, value: string) => void
}
const PersonalInfo = (p: personalInfoProps) => {
    const app = useContext(LocaleContext)
    const [emailExists, setEmailExists] = useState(false);

    const checkExistingEmail = async () => {
        const response = await m.LocalClient.Public.IsEmailExists(p.person!.Email!)
        setEmailExists(response);
    }
    var usr = app?.data?.User
    return <>
        <h2>פרטים</h2>
        <div className="clsFild regularInput">
            <span>שם פרטי *</span>
            <input disabled={usr !== undefined} value={p.person?.FirstName} type="text" placeholder="" onChange={x => p.handleChange('FirstName', x.target.value)} />
        </div>
        <div className="clsFild regularInput clsNoneMargin">
            <span>שם משפחה *</span>
            <input disabled={usr !== undefined} value={p.person?.LastName} type="text" placeholder="" onChange={x => p.handleChange('LastName', x.target.value)} />
        </div>
        <div className="clsFild largeInput">
            <span>כתובת מייל *</span>
            <input disabled={usr !== undefined} value={p.person?.Email} type="text" placeholder=""
                onChange={x => p.handleChange('Email', x.target.value)}
                onBlur={checkExistingEmail} />
        </div>
        {emailExists && <div className="clsFild largeInput" style={{ marginBottom: '20px' }}>
            <span style={{ color: 'red' }} >This email address is allready registered. Please Login</span>
            <span  >
                If you need any assistance please
                <Link to={'/contact'} style={{ textDecoration: 'underline' }}> Contact Us</Link>
            </span>
        </div>}
        <div className="clsFild largeInput">
            <span>שם חברה</span>
            <input value={p.person?.CompanyName} type="text" placeholder="" onChange={x => p.handleChange('CompanyName', x.target.value)} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="clsFild regularInput">
                <span>טלפון *</span>
                <input value={p.person?.Number} type="text" placeholder="" onChange={x => p.handleChange('Number', x.target.value)} />
            </div>
            <div className="clsFild regularInput">
                <span>נייד *</span>
                <input value={p.person?.Cell} type="text" placeholder="" onChange={x => p.handleChange('Cell', x.target.value)} />
            </div>
        </div>
    </>
}
export default PersonalInfo
