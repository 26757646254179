//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect } from 'react';
import AddressBox from './AddressBox';
import NewAddressBox from './NewAddressBox';
//import axios from 'axios';
import { serverUrl } from '../tools';
import * as m from '../Declarations'
// ]
//const addressObject = {
//     CityName: '',
//     Zip: '',
//     Street: '',
//     House: '',
//     Floor: '',
//     Apt: '',
//     StateName: '',
//     CountryName: '',
//     CountryIso: '',
//     StateIso: '',
//     Attention: '',
//     Entrance: '',
//     CountyName: '',
// }
interface AddressContainerProps {
    isShipping: boolean, addressId: number, setAddressId: (id: number, isShipping: boolean) => void, billingAndShipping?: boolean, setBillingAndShipping?: (b: boolean) => void
}
const AddressContainer = (props: AddressContainerProps) => {
    const [addressList, setAddressList] = useState<m.LocalAddressExt[]>([]);
    useEffect(() => {
        fetchAddresses()
    }, [])

    const fetchAddresses = async () => {
        console.log('fetching addresses')
        const data = await m.LocalClient.Public.GetAddresses()
        setAddressList(data)
    }

    useEffect(() => {
        console.log('addresslist: ', addressList)
        const ordinals = [...addressList].map(x => x.Ordinal);
        const id = [...addressList].find(q => q.Ordinal === Math.max(...ordinals))?.ID;
        console.log('addressId: ', id, props.isShipping)
        props.setAddressId(id!, props.isShipping)
    }, [addressList, props.isShipping]);

    //#region NEW ADDRESS 
    const [newAddress, setNewAddress] = useState<m.AddressData>();

    const saveNewAddress = async () => {
        // save on server  and return new address 
        m.LocalClient.Public.SaveAddress(newAddress!)
        // add to list of addreses
        fetchAddresses();
        setNewAddress(new m.AddressData);
    };

    // const handleNewAddressChange = (data:m.AddressData) => {
    //     setNewAddress(prev => ({ ...prev, [field]: value }))
    // };

    //#endregion 

    const deleteAddress = (id: number) => {
        m.LocalClient.Public.HideAddress(id);
        const newList = addressList.filter(x => x.ID !== id);
        setAddressList(newList);
    }

    const selectAddress = (id: number) => {
        props.setAddressId(id, props.isShipping)
    }


    return (
        <>
            <h2>{props.isShipping ? 'כתובת משלוח' : 'כתובת חיוב'}</h2>
            <div style={{ display: "grid", gridTemplateColumns: 'repeat(auto-fill, minmax(250px,275px))', gap: '20px' }}>
                {addressList.map(address => <AddressBox
                    key={address.ID}
                    address={address}
                    isSelected={props.addressId === address.ID}
                    handelSelect={selectAddress}
                    handelDelete={deleteAddress} />)}
                <NewAddressBox
                    newAddress={newAddress!}
                    handleChange={setNewAddress}
                    isShipping={props.isShipping}
                    handelSave={saveNewAddress} />
            </div>
            {!props.isShipping &&
                <ul>
                    <li>
                        <input type="checkbox" id="chck" checked={props.billingAndShipping} onChange={e => props.setBillingAndShipping && props.setBillingAndShipping(!props.billingAndShipping)} />
                        <label htmlFor="chck">המשלוח הוא לאותו כתובת</label>
                    </li>
                </ul>}
        </>
    )
}

export default AddressContainer;