import React from "react";
import { performAction, performActionT } from "./general";
export class AddressData{
  Apt?: string = undefined;
  CityName?: string = undefined;
  CountryIso?: string = undefined;
  CountryName?: string = undefined;
  FirstName?: string = undefined;
  House?: number = undefined;
  HouseName?: string = undefined;
  Entrance?: number = undefined;
  LastName?: string = undefined;
  Prefix?: string = undefined;
  Caption?: string = undefined;
  Attention?: string = undefined;
  Latitude?: number = undefined;
  Longitude?: number = undefined;
  StateIso?: string = undefined;
  StateName?: string = undefined;
  UnverifiedAddress?: string = undefined;
  Emails: string[] = [];
  InfoSource?: string = undefined;
  TimeZone?: string = undefined;
  Street?: string = undefined;
  Zip?: string = undefined;
  ZipExt?: number = undefined;
  CountyName?: string = undefined;
  Floor?: number = undefined;
  POB?: number = undefined;
  POBox?: number = undefined;
  Type?: string = undefined;
  Verified: boolean = false;
  IsPrepaid: boolean = false;
  Carrier?: string = undefined;
  Comment?: string = undefined;
  Address?: string = undefined;
  Phones: {Key: number, Value: PhoneTypes}[] = [];
  Groups: string[] = [];
  ServerData?: string = undefined;
  GooglePlaceCode?: string = undefined;
  LastConfirmedOn?: Date = undefined;
  Active: boolean = false;
  HasSms: boolean = false;
  PersonName?: string = undefined;
  StreetLine?: string = undefined;
}

export enum Aggregations {
    Count,
    Sum,
    Average,
    Min,
    Max,
}

export enum AlertLevels {
    Error,
    Warning,
}

export class AppData{
  UseLocalText: boolean = false;
  Language?: string = undefined;
  IsRtl: boolean = false;
  IsRemote: boolean = false;
  CommandsInSameTab: boolean = false;
  PickerTimeFormat?: string = undefined;
  PickerDateFormat?: string = undefined;
  UserID?: number = undefined;
  ResultOptions?: string = undefined;
  IsSysAdmin: boolean = false;
  IsSandbox: boolean = false;
  UserName?: string = undefined;
  PrimaryColor?: number = undefined;
  SecondaryColor?: number = undefined;
  SelectedDomainID?: number = undefined;
  DomainName?: string = undefined;
  GridColumns: number = 0;
  IsDebug: boolean = false;
  CoinSymbol?: string = undefined;
  NodePrefix?: string = undefined;
}

export interface Attachment{
  Title?: string;
  Inline: boolean;
  ContentType?: string;
  Extension?: string;
  Bytes: string;
  Data?: string;
  Text?: string;
  Name?: string;
}

export enum AttachmentTypes {
    Pdf,
    Html,
    Csv,
    Xlsx,
}

export enum AutoFilters {
    Empties,
    Fulls,
    Mine,
    Yes,
    No,
    NotZero,
    ThisWeekday,
    NotRelated,
    Duplicates,
    Positive,
    Negative,
}

export interface BarFilter{
  FieldName?: string;
  ColumnData: ColumnData;
  List: any[];
  DisplayName?: string;
  Options: FieldCountData[];
}

export enum ChangeTypes {
    Insert,
    Update,
    Delete,
}

export class ClipboardData{
  Text?: string = undefined;
  Html?: string = undefined;
}

export interface ColumnChoice{
  Group: SettingGroup;
  RecordType?: string;
  ReportID?: number;
  Key?: string;
  Columns: ColumnData[];
  ForAll: boolean;
  ReportMode: boolean;
}

export interface ColumnData{
  LocalName?: string;
  ReportFieldID?: number;
  Visible: boolean;
  Encryption: boolean;
  UserID?: number;
  FooterAggregation?: Aggregations;
  Formula?: string;
  Definition?: string;
  SelectName?: string;
  FilterBar: boolean;
  IsFilter: boolean;
  Format?: string;
  MaxDecimals?: number;
  Invert: boolean;
  ShowTime?: boolean;
  DateInterval?: Intervals;
  IsEnum: boolean;
  ColoredBoolean: boolean;
  ValueBorderColor?: number;
  Font?: string;
  Language?: string;
  Align?: TextAligns;
  MaxHeight?: number;
  MaxWidth?: number;
  Editable: boolean;
  ForAppList: boolean;
  NewLine: boolean;
  SelectClause?: string;
  SettingGroup?: SettingGroup;
  NameFontSize?: number;
  NameBackColor?: number;
  ValueFontSize?: number;
  ValuePadding?: number;
  Key?: string;
  CanRead: boolean;
  SelectedPosition: number;
  RelevantAutoFilters: {Key: AutoFilters, Value: string}[];
  PossibleValues: {Key: number, Value: string}[];
  Name?: string;
  DataType?: DataTypes;
  FieldType?: FieldTypes;
  IDFieldName?: string;
  ForeignTypeName?: string;
  Warning?: string;
  Group?: string;
  SubReportID?: number;
  PickReportID?: number;
  FontSize?: number;
  FontWeight?: number;
  Uses: number;
  IsNew: boolean;
  LetTime: boolean;
  IsCommand: boolean;
  Multiline: boolean;
  MaxLength?: number;
  Internal: boolean;
  AutoClose: boolean;
  NoChange: boolean;
  ShowAll: boolean;
  AutoComplete: boolean;
  WordWrap: boolean;
  NeedsField: boolean;
  NeedsReport: boolean;
  NeedsList: boolean;
  CausesUnconfirm: boolean;
  Locked: boolean;
  IsStatic: boolean;
  SpecialPicker: boolean;
  ForeignID?: number;
  ForeColor?: number;
  BackColor?: number;
  SortOrder: number;
  Tooltip?: string;
  Icon?: string;
  RecordType?: string;
  MeasureUnit?: Units;
  MeasureType?: MeasureTypes;
  Picklist: {Key: any, Value: string}[];
  CommandMembers: any[];
}

export interface CommandList{
  RecordType?: string;
  Filters: MiniReportField[];
}

export interface CommandMessage{
  Subject?: string;
  Body?: string;
  Recipients: {Key: string, Value: string}[];
  BlindCopies: {Key: string, Value: string}[];
  FaxNumber?: number;
  Attachments: Attachment[];
  MailboxID?: number;
  ScreenName?: string;
  ReplyAddress?: string;
  UserName?: string;
  Password?: string;
  LinkedIDs: { [index:string]: number };
  ReportID?: number;
  GetCopy: boolean;
  PersonID?: number;
  SendSplit: boolean;
}

export interface CommandResult{
  ObjectType?: string;
  Object?: any;
  Record: RecordData;
  Fields: ControlProps[];
}

export interface ContactForm{
  Name?: string;
  Email?: string;
  Phone?: string;
  Message?: string;
}

export interface ControlProps{
  DebugInfo?: string;
  Value?: any;
  DisplayString?: string;
  Editable: boolean;
  RecordName?: string;
  ErrorText?: string;
  ErrorRecordID?: number;
  Position?: number;
  SubTableName?: string;
  SubTableProps: RecordData;
  DomainID?: number;
  Useful: boolean;
  Required: boolean;
  DisplayName?: string;
  Visible: boolean;
  Member?: string;
  Name?: string;
  DataType?: DataTypes;
  FieldType?: FieldTypes;
  IDFieldName?: string;
  ForeignTypeName?: string;
  Warning?: string;
  Group?: string;
  SubReportID?: number;
  PickReportID?: number;
  FontSize?: number;
  FontWeight?: number;
  Uses: number;
  IsNew: boolean;
  LetTime: boolean;
  IsCommand: boolean;
  Multiline: boolean;
  MaxLength?: number;
  Internal: boolean;
  AutoClose: boolean;
  NoChange: boolean;
  ShowAll: boolean;
  AutoComplete: boolean;
  WordWrap: boolean;
  NeedsField: boolean;
  NeedsReport: boolean;
  NeedsList: boolean;
  CausesUnconfirm: boolean;
  Locked: boolean;
  IsStatic: boolean;
  SpecialPicker: boolean;
  ForeignID?: number;
  ForeColor?: number;
  BackColor?: number;
  SortOrder: number;
  Tooltip?: string;
  Icon?: string;
  RecordType?: string;
  MeasureUnit?: Units;
  MeasureType?: MeasureTypes;
  Picklist: {Key: any, Value: string}[];
  CommandMembers: any[];
}

export interface DashboardData{
  Entities: DashboardTile[];
  Recents: DashboardTile[];
}

export interface DashboardTile{
  RecordType?: string;
  ReportID?: number;
  RecordID?: number;
  DisplayName?: string;
  Count?: number;
  AggregationFieldType?: FieldTypes;
  SortOrder?: number;
  IsLicensed: boolean;
  ForeColor?: number;
  BackColor?: number;
  Key?: string;
}

export enum DataTypes {
    String,
    Integer,
    Boolean,
    DateTime,
    Enum,
    Bytes,
    TimeSpan,
}

export enum DateRanges {
    Today,
    ThisWeek,
    ThisMonth,
    ThisYear,
    Past,
    Future,
    PastWeek,
    PastMonth,
    PastYear,
    LastMonth,
    Yesterday,
    LastYear,
    NextMonth,
    ThisSchoolYear,
    PastHour,
}

export enum DefaultTypes {
    Now,
    Today,
    Mine,
    Yes,
    SchoolYearStart,
}

export interface EditMember{
  FieldTitle?: string;
  OldValue?: any;
  NewValue?: any;
  ChangeType: ChangeTypes;
}

export interface FieldCountData{
  Key?: string;
  Value?: string;
  Distance: number;
  Count: number;
}

export enum FieldTypes {
    Bitmap,
    Clock,
    Color,
    Css,
    Csv,
    DrawPath,
    Email,
    Gender,
    Html,
    IsraelNationalCode,
    Measure,
    Money,
    Percent,
    Phone,
    Span,
    Weekdays,
    Track,
    Json,
    Url,
    Rtf,
    Xaml,
    FolderPath,
    Language,
    Xml,
    Audio,
    CardExpiry,
    Weekday,
    Font,
    Pdf,
    DateFormat,
    Docx,
    Svg,
    TimeFormat,
    CardNumber,
    SocialSecurity,
}

export interface FileProps{
  Bytes: string;
  Type?: string;
  Data?: string;
  Text?: string;
  Pages: number;
  Name?: string;
  File: File;
  Html?: string;
}

export interface FilterDescription{
  Group: number;
  Descriptions: {Key: string, Value: string}[];
}

export interface fncEntitySetting{
  RecordType?: string;
  PasswordDelete: boolean;
  ForTopMenu: boolean;
  SectionID?: number;
  Color?: number;
  SectionName?: string;
  ForOffline: boolean;
  Title?: string;
  OfflineKeepSpan?: number;
  OfflineSyncFrequency?: number;
  ImportSkipRows: number;
  ImportDateFormat?: string;
  ForBackup?: boolean;
  LocalRecordType?: string;
  ImportCodePage?: number;
  FolderPath?: string;
  ImportSkipDataRows: number;
  IsLicensed: boolean;
  DisplayName?: string;
  BaseEntityName?: string;
  ListName?: string;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export class GridProps{
  Term?: string = undefined;
  ReportID?: number = undefined;
  ParentRecordID?: number = undefined;
  SettingGroup: SettingGroup = 0;
  Member?: string = undefined;
  InputParams: {Key: string, Value: any}[] = [];
  Fields: MiniReportField[] = [];
  RecordKeys: Set<string> = new Set([]);
  Page: number = 0;
  SettingKey?: string = undefined;
  FilterReportID?: number = undefined;
  CalendarWeekView: boolean = false;
  CalendarFromDate?: Date = undefined;
  DisplayType?: ReportTypes = undefined;
  Command?: string = undefined;
}

export interface ImportSetting{
  ImportColumns: MiniImportColumn[];
  EntityColumns: {Key: string, Value: string}[];
}

export enum Intervals {
    Millisecond,
    Second,
    Minute,
    Hour,
    Day,
    Week,
    Month,
    Year,
}

export interface KeyData{
  ForeignField?: string;
  IDFieldName?: string;
}

export interface LiteCallBase{
  Code?: string;
  PersonID?: number;
  Status?: string;
  StartDate: Date;
  EndDate?: Date;
  Transcript?: string;
  Incoming: boolean;
  FromPhone?: number;
  ToPhone?: number;
  PersonName?: string;
  Duration?: number;
  ViaSipClient: boolean;
  SipAddress?: string;
  ScreenName?: string;
  ScreenNumber?: string;
  Message: string;
  MessageSize?: number;
  ServerData?: string;
  WorkerID?: number;
  FromExtension?: number;
  ToExtension?: number;
  WorkerName?: string;
  Channel?: string;
  ReachedPhone?: number;
  RawData?: string;
  Provider?: string;
  MainCode?: number;
  RecordingsUrl?: string;
  RecordingChecked?: Date;
  FromPhoneString?: string;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export interface LiteMessageBase{
  Subject?: string;
  Folder?: string;
  Headers?: string;
  MailboxID: number;
  Code?: number;
  Body?: string;
  BodyText?: string;
  References?: string;
  UserName?: string;
  ScreenName?: string;
  FailedRecipient?: string;
  FailedReason?: string;
  SenderID?: number;
  ReplyEmail?: string;
  SentDate: Date;
  SenderName?: string;
  Reference?: string;
  MailboxName?: string;
  Response?: string;
  WorkerID?: number;
  WorkerName?: string;
  SendWorkerID?: number;
  SendWorkerName?: string;
  Incoming?: boolean;
  ReplyPersonID?: number;
  ReplyPersonName?: string;
  ReportID?: number;
  ReportName?: string;
  Flags?: string;
  LastSync?: Date;
  ApiCode?: string;
  DomainID?: number;
  DomainName?: string;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export interface LoginModel{
  Email?: string;
  Password?: string;
  LastName?: string;
  FirstName?: string;
}

export enum LogModes {
    Insert,
    Change,
    Delete,
    None,
}

export enum MeasureTypes {
    Capacity,
    Length,
    Volume,
    Temperature,
    Weight,
    Area,
}

export interface MiniExecution{
  ID: number;
  LocalRecordType?: string;
  LocalCommand?: string;
  Progress: number;
}

export interface MiniImportColumn{
  ID: number;
  Name?: string;
  FieldName?: string;
  SubRecordType?: string;
  SubFieldName?: string;
  IsKey: boolean;
  Row?: number;
  LocalSubRecordType?: string;
  Samples: string[];
}

export interface MiniRecordData{
  RecordType?: string;
  RecordID?: number;
  BackColor?: number;
  ForeColor?: number;
  RecordName?: string;
  RecordKey?: string;
  IsDeleted: boolean;
  QuickAdds: {Key: string, Value: string}[];
  SimpleFields: { [index:string]: any };
  Index: number;
}

export class MiniReportField{
  FieldName?: string = undefined;
  SelectName?: string = undefined;
  IDFilter?: string = undefined;
  FilterGroup?: number = undefined;
  Formula?: string = undefined;
  FilterList?: string = undefined;
  RecordType?: string = undefined;
  SortOrder?: number = undefined;
  SortDescending: boolean = false;
  FooterAggregation?: Aggregations = undefined;
  DisplayName?: string = undefined;
  FilterText?: string = undefined;
  MinFilter?: string = undefined;
  MaxFilter?: string = undefined;
  IncludeEmpties: boolean = false;
  ReverseFilter: boolean = false;
  DateRange?: DateRanges = undefined;
  AutoFilter?: AutoFilters = undefined;
  CompareRecordType?: string = undefined;
  CompareSubFieldValue?: string = undefined;
  Visible: boolean = false;
  StartSpan?: number = undefined;
  EndSpan?: number = undefined;
  BarFilterValues: string[] = [];
}

export interface NavData{
  Domains: {Key: number, Value: string}[];
  AppName?: string;
  Alerts: ReportLite[];
  Populars: ReportLite[];
  Tools: string[];
  AllLists: string[];
  Lists: DashboardTile[];
  SystemAlert: {Key: string, Value: string};
  QuickAdds: {Key: string, Value: string}[];
  Progresses: MiniExecution[];
  LiveCalls: LiteCallBase[];
  NewMessages: LiteMessageBase[];
  Printables: string[];
  Recents: DashboardTile[];
}

export enum Operators {
    Equals,
    In,
    LessThan,
    LessThanOrEquals,
    GreaterThan,
    GreaterThanOrEquals,
}

export enum OutputType {
    Html,
    Csv,
    Speech,
    Json,
    Sms,
}

export enum PhoneTypes {
    Phone,
    Cell,
    Fax,
    Pager,
}

export interface PortalData{
  UserID?: number;
  Reports: ReportLite[];
  CompanyName?: string;
  UserName?: string;
}

export interface ProgressData{
  Description?: string;
  Index?: number;
  Total?: number;
  Date: Date;
}

export interface RecordData{
  Fields: ControlProps[];
  Reports: ReportLite[];
  RecordType?: string;
  RecordID?: number;
  BackColor?: number;
  ForeColor?: number;
  RecordName?: string;
  RecordKey?: string;
  IsDeleted: boolean;
  QuickAdds: {Key: string, Value: string}[];
  SimpleFields: { [index:string]: any };
  Index: number;
}

export interface RecordError{
  FieldName?: string;
  FieldTitle?: string;
  Value?: any;
  Message?: string;
  RecordType?: string;
  Priority?: AlertLevels;
  Useless: boolean;
  RecordID?: number;
  DuplicateRecordID?: number;
}

export interface ReportLite{
  Count: number;
  LastCount: Date;
  RecountSpan?: any;
  BackColor?: number;
  ForeColor?: number;
  Type: ReportTypes;
  Position: number;
  DisplayString?: string;
  AlertLevel?: AlertLevels;
  ForDashboard: boolean;
  ShowCount: boolean;
  Language?: string;
  MainRecordField?: string;
  Command?: string;
  Frequency: number;
  DoneOn?: Date;
  ParentID?: number;
  IsAlert: boolean;
  Group?: string;
  ForTopMenu: boolean;
  PauseTill?: Date;
  DomainID?: number;
  JoinRecordType?: string;
  ForRestDays: boolean;
  ValueFontSize?: number;
  WorkingOn?: Date;
  RetryDelay?: any;
  UserID?: number;
  EvalRecordType?: string;
  ReverseJoin: boolean;
  ForPortal: boolean;
  Uses: number;
  ForMainRecord: boolean;
  Paused: boolean;
  ShowWhenEmpty: boolean;
  LocalSmsTemplate?: string;
  LocalName?: string;
  HasFilter: boolean;
  AlertColor?: number;
  TempCount?: number;
  AggregationFieldType?: FieldTypes;
  FilterDescriptions: FilterDescription[];
  ID: number;
  Name?: string;
  RecordType?: string;
  MainRecordType?: string;
  AddedOn: Date;
  ForOffline?: boolean;
  LastSync?: Date;
  KeyFields: string[];
  IsUnion: boolean;
  Active: boolean;
  ForPrint: boolean;
  RecipientField?: string;
}

export enum ReportTypes {
    List,
    Single,
    Count,
    Calendar,
    Cards,
    BarChart,
    LineChart,
    Gantt,
    PieChart,
}

export interface SearchResult{
  Name?: string;
  Value?: string;
  RecordName?: string;
  RecordID?: number;
  RecordType?: string;
}

export interface SearchResultGroup{
  Name?: string;
  LocalName?: string;
  List: SearchResult[];
  Total: number;
}

export enum SettingGroup {
    AppListSort,
    EditFields,
    Columns,
    ColumnsWidth,
    WindowsEditFields,
    EditPage,
    ColumnsSetAll,
    Options,
    PageRows,
    PanelView,
    RecordPage,
    SetValue,
    ShowAllLists,
    ShowPreview,
    Sorting,
    Splitter,
    TabSelection,
    WindowRect,
    RecordPrint,
    PrintColumns,
    CsvColumns,
    ShowFilters,
    PreviewFields,
    AppDetails,
    Misc,
    EditColumns,
    PickColumns,
    Dashboard,
}

export interface ShipData{
  Shipper: Shipper;
  ShipTypeID: number;
  ShipCost: number;
  InsuranceCost: number;
  ServiceName?: string;
  ServiceCode: number;
  ForCart: boolean;
  DeliverDays?: number;
  DeliverTime?: any;
  Container?: string;
  TotalCost: number;
}

export enum Shipper {
    Fedex,
    Ups,
    Usps,
}

export interface TestingData{
  IsRemote: boolean;
  PickerDateFormat?: string;
  Reports: any[];
  Members: any[];
}

export enum TextAligns {
    Center,
    Start,
    End,
    Right,
    Left,
}

export enum Units {
    Bytes,
    Centimeters,
    Celsius,
    Farenheit,
    Feet,
    FluidOunces,
    Gallons,
    Gigabytes,
    Grams,
    Inches,
    Kilobytes,
    Kilograms,
    Kilometers,
    Liters,
    Megabytes,
    Meters,
    Miles,
    Miligrams,
    Milimeters,
    Mililiters,
    Ounces,
    Pixels,
    Points,
    Pounds,
    Terabytes,
    SquareCentimeters,
    SquareMeters,
    Tons,
}

export interface ViewTable{
  Records: RecordData[];
  Sums: { [index:string]: string };
  BarFilters: BarFilter[];
  ShowPreview: boolean;
  DisplayName?: string;
  PageRows: number;
  ValueBorderColor?: number;
  InputParams: ControlProps[];
  Filters: FilterDescription[];
  AllowedKeys: string[];
  ReportType?: ReportTypes;
  FilterReports: {Key: number, Value: string}[];
  Columns: ColumnData[];
  ListType?: string;
}
export const MachshevetClient = {Global: {GetCommands: (RecordType: string,ReportID?: number, signal?: AbortSignal) => performActionT<ControlProps[]>("Global", "GetCommands", {RecordType,ReportID},true, signal),

InputCommand2: (RecordType: string,MainCommand: string,Command: string,Records: string[],Input: any, signal?: AbortSignal) => performAction("Global", "InputCommand2", {RecordType,MainCommand,Command,Records,Input},true, signal),

InputCommand: (RecordType: string,MainCommand: string,Command: string,Records: Set<string>,Input: {Key: string, Value: any}[],Url: string, signal?: AbortSignal) => performAction("Global", "InputCommand", {RecordType,MainCommand,Command,Records,Input,Url},true, signal),

Preview: (RecordType: string,ID: number, signal?: AbortSignal) => performActionT<RecordData>("Global", "Preview", {RecordType,ID},false, signal),

Dial: (Number: number, signal?: AbortSignal) => performAction("Global", "Dial", {Number},false, signal),

SetMicrosoftAuthorization: (Code: string, signal?: AbortSignal) => performAction("Global", "SetMicrosoftAuthorization", {Code},false, signal),

GetColumnData: (Group: SettingGroup,Key?: string,RecordType?: string,ForAll?: boolean,ReportID?: number, signal?: AbortSignal) => performActionT<ColumnData[]>("Global", "GetColumnData", {Group,Key,RecordType,ForAll,ReportID},false, signal),

SetColumns: (Choices: ColumnChoice, signal?: AbortSignal) => performAction("Global", "SetColumns", {Choices},true, signal),

SetAuthorizationCode: (Provider: string,Code: string, signal?: AbortSignal) => performAction("Global", "SetAuthorizationCode", {Provider,Code},false, signal),

GetGoogleKey: ( signal?: AbortSignal) => performActionT<string>("Global", "GetGoogleKey", {},false, signal),

Ping: ( signal?: AbortSignal) => performAction("Global", "Ping", {},false, signal),

GlobalSearch: (Term: string, signal?: AbortSignal) => performActionT<SearchResultGroup[]>("Global", "GlobalSearch", {Term},false, signal),

OptionList: ( signal?: AbortSignal) => performActionT<ControlProps[]>("Global", "OptionList", {},false, signal),

SetOptions: (Changes: { [index:string]: string }, signal?: AbortSignal) => performAction("Global", "SetOptions", {Changes},true, signal),

SetValue: (RecordType: string,RecordKeys: Set<string>,FieldName: string,NewValue: any, signal?: AbortSignal) => performAction("Global", "SetValue", {RecordType,RecordKeys,FieldName,NewValue},true, signal),

GetSelectOptions: (FieldName: string, signal?: AbortSignal) => performActionT<{Key: any, Value: string}[]>("Global", "GetSelectOptions", {FieldName},false, signal),

GetInputOptions: (RecordType: string,Command: string,FieldName: string,Record: ControlProps[], signal?: AbortSignal) => performActionT<{Key: any, Value: string}[]>("Global", "GetInputOptions", {RecordType,Command,FieldName,Record},true, signal),

MarkRecordSeen: (RecordType: string,ID: number, signal?: AbortSignal) => performAction("Global", "MarkRecordSeen", {RecordType,ID},false, signal),

MarkReportSeen: (ReportID: number, signal?: AbortSignal) => performAction("Global", "MarkReportSeen", {ReportID},false, signal),

FixDatabase: (RemoteAlso?: boolean, signal?: AbortSignal) => performAction("Global", "FixDatabase", {RemoteAlso},false, signal),

GetLists: ( signal?: AbortSignal) => performAction("Global", "GetLists", {},false, signal),

SetSetting: (Name: string,Value: string,Group: SettingGroup,RecordType?: string,ReportID?: number, signal?: AbortSignal) => performAction("Global", "SetSetting", {Name,Value,Group,RecordType,ReportID},false, signal),

GetSetting: (Name: string,Group: SettingGroup, signal?: AbortSignal) => performAction("Global", "GetSetting", {Name,Group},false, signal),

Static: (path: string, signal?: AbortSignal) => performAction("Global", "Static", {path},false, signal),

AppStatic: (path: string, signal?: AbortSignal) => performAction("Global", "AppStatic", {path},false, signal),

VectorIcon: (Name: string, signal?: AbortSignal) => performAction("Global", "VectorIcon", {Name},false, signal),

Favicon: ( signal?: AbortSignal) => performAction("Global", "Favicon", {},false, signal),

AddCaching: (Span?: any, signal?: AbortSignal) => performAction("Global", "AddCaching", {Span},false, signal),

Logo: ( signal?: AbortSignal) => performAction("Global", "Logo", {},false, signal),

DashboardBackground: ( signal?: AbortSignal) => performAction("Global", "DashboardBackground", {},false, signal),

Localize: (value: string, signal?: AbortSignal) => performActionT<string>("Global", "Localize", {value},false, signal),

UITexts: (Language: string, signal?: AbortSignal) => performActionT<{ [index:string]: string }>("Global", "UITexts", {Language},false, signal),

ClearCache: ( signal?: AbortSignal) => performAction("Global", "ClearCache", {},false, signal),

ClearBrowser: ( signal?: AbortSignal) => performAction("Global", "ClearBrowser", {},false, signal),

FindEmailAddresses: (input: string, signal?: AbortSignal) => performActionT<{Key: string, Value: string}[]>("Global", "FindEmailAddresses", {input},false, signal),

Mailboxes: ( signal?: AbortSignal) => performActionT<{Key: number, Value: {Key: number, Value: string}[]}>("Global", "Mailboxes", {},false, signal),

FindPlaces: (Term: string,type?: string, signal?: AbortSignal) => performActionT<{Key: string, Value: string}[]>("Global", "FindPlaces", {Term,type},false, signal),

GetPlaceDetails: (Key: string, signal?: AbortSignal) => performActionT<AddressData>("Global", "GetPlaceDetails", {Key},false, signal),

DoCommand: (Name: string, signal?: AbortSignal) => performAction("Global", "DoCommand", {Name},false, signal),

DownloadData: ( signal?: AbortSignal) => performAction("Global", "DownloadData", {},false, signal),

PhoneCallback: ( signal?: AbortSignal) => performAction("Global", "PhoneCallback", {},false, signal),

SetDomainID: (DomainID?: number, signal?: AbortSignal) => performAction("Global", "SetDomainID", {DomainID},false, signal),

GetAppData: ( signal?: AbortSignal) => performActionT<AppData>("Global", "GetAppData", {},false, signal),

GetNavData: ( signal?: AbortSignal) => performActionT<NavData>("Global", "GetNavData", {},false, signal),

GetDashboardData: ( signal?: AbortSignal) => performActionT<DashboardData>("Global", "GetDashboardData", {},false, signal),

SendMail: (Input: CommandMessage, signal?: AbortSignal) => performAction("Global", "SendMail", {Input},true, signal),

GetPerson: (Email: string,Columns?: string[], signal?: AbortSignal) => performAction("Global", "GetPerson", {Email,Columns},false, signal),

PortalList: (ReportID: number, signal?: AbortSignal) => performActionT<ViewTable>("Global", "PortalList", {ReportID},false, signal),

GetPortalData: ( signal?: AbortSignal) => performActionT<PortalData>("Global", "GetPortalData", {},false, signal),

DeleteRecord: (RecordType: string,RecordID: number, signal?: AbortSignal) => performAction("Global", "DeleteRecord", {RecordType,RecordID},false, signal),

GetTestingData: ( signal?: AbortSignal) => performActionT<TestingData>("Global", "GetTestingData", {},false, signal),

OnAuthorization: (filterContext: any, signal?: AbortSignal) => performAction("Global", "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (Input: any,DateFormat?: string,EnumStrings?: boolean, signal?: AbortSignal) => performAction("Global", "SafeJsonResult", {Input,DateFormat,EnumStrings},false, signal),

CommandResult: (Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction("Global", "CommandResult", {Result,Context,Command},false, signal),

},Login: {GenerateTempPassword: (LoginUserName: string, signal?: AbortSignal) => performAction("Login", "GenerateTempPassword", {LoginUserName},false, signal),

Login: (LoginUserName: string,LoginPassword: string, signal?: AbortSignal) => performActionT<{Key: number, Value: string}>("Login", "Login", {LoginUserName,LoginPassword},false, signal),

LogOut: ( signal?: AbortSignal) => performAction("Login", "LogOut", {},false, signal),

Register: (Model: LoginModel, signal?: AbortSignal) => performAction("Login", "Register", {Model},true, signal),

KeepSessionLive: ( signal?: AbortSignal) => performAction("Login", "KeepSessionLive", {},false, signal),

OnAuthorization: (filterContext: any, signal?: AbortSignal) => performAction("Login", "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (Input: any,DateFormat?: string,EnumStrings?: boolean, signal?: AbortSignal) => performAction("Login", "SafeJsonResult", {Input,DateFormat,EnumStrings},false, signal),

CommandResult: (Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction("Login", "CommandResult", {Result,Context,Command},false, signal),

},GetRecordName: (controller: string, ID: number, signal?: AbortSignal) => performActionT<string>(controller, "GetRecordName", {ID},false, signal),

GetDoc: (controller: string, Column: string,ID: number,Download?: boolean,Page?: number,Width?: number, signal?: AbortSignal) => performAction(controller, "GetDoc", {Column,ID,Download,Page,Width},false, signal),

Insert: (controller: string, Records: any[], signal?: AbortSignal) => performAction(controller, "Insert", {Records},true, signal),

ApiSaveEdit: (controller: string, Record: any,Url: string, signal?: AbortSignal) => performActionT<RecordData>(controller, "ApiSaveEdit", {Record,Url},true, signal),

RecordData: (controller: string, Record: any,ChangedFieldName?: string,Index?: number,SubFieldName?: string, signal?: AbortSignal) => performActionT<RecordData>(controller, "RecordData", {Record,ChangedFieldName,Index,SubFieldName},true, signal),

PageRecord: (controller: string, RecordID?: number,Record?: any,PresetValues?: {Key: string, Value: any}[], signal?: AbortSignal) => performActionT<RecordData>(controller, "PageRecord", {RecordID,Record,PresetValues},true, signal),

ImportColumnNames: (controller: string, File: File, signal?: AbortSignal) => performActionT<ImportSetting>(controller, "ImportColumnNames", {File},true, signal),

SaveImportColumns: (controller: string, Columns: MiniImportColumn[], signal?: AbortSignal) => performActionT<MiniImportColumn[]>(controller, "SaveImportColumns", {Columns},true, signal),

GetImportColumns: (controller: string,  signal?: AbortSignal) => performActionT<MiniImportColumn[]>(controller, "GetImportColumns", {},false, signal),

Import: (controller: string, File: File,Columns: MiniImportColumn[],MaxRows?: number,SkipRows?: number, signal?: AbortSignal) => performActionT<number>(controller, "Import", {File,Columns,MaxRows,SkipRows},true, signal),

GetEntitySettingID: (controller: string,  signal?: AbortSignal) => performActionT<number>(controller, "GetEntitySettingID", {},false, signal),

EntitySetting: (controller: string,  signal?: AbortSignal) => performActionT<fncEntitySetting>(controller, "EntitySetting", {},false, signal),

GetSelectOptions: (controller: string, Record: any,SelectFieldName: string, signal?: AbortSignal) => performActionT<{Key: any, Value: string}[]>(controller, "GetSelectOptions", {Record,SelectFieldName},true, signal),

GetAllIDs: (controller: string, GridProps: GridProps, signal?: AbortSignal) => performActionT<number[]>(controller, "GetAllIDs", {GridProps},true, signal),

GetFieldSettingID: (controller: string, FieldName: string, signal?: AbortSignal) => performActionT<number>(controller, "GetFieldSettingID", {FieldName},false, signal),

FieldCount: (controller: string, GridProps: GridProps,Field: string,Term?: string, signal?: AbortSignal) => performActionT<FieldCountData[]>(controller, "FieldCount", {GridProps,Field,Term},true, signal),

GetOfflineList: (controller: string, ReportID?: number, signal?: AbortSignal) => performAction(controller, "GetOfflineList", {ReportID},false, signal),

GetListType: (controller: string, GridProps: GridProps,InputModel?: ControlProps[], signal?: AbortSignal) => performAction(controller, "GetListType", {GridProps,InputModel},false, signal),

InsertGrid: (controller: string,  signal?: AbortSignal) => performActionT<RecordData>(controller, "InsertGrid", {},false, signal),

GetCount: (controller: string, Model: any,GridProps: GridProps,InputModel?: ControlProps[], signal?: AbortSignal) => performActionT<number>(controller, "GetCount", {Model,GridProps,InputModel},true, signal),

QuickSearch: (controller: string, Model: any,GridProps: GridProps,InputModel?: ControlProps[], signal?: AbortSignal) => performActionT<ViewTable>(controller, "QuickSearch", {Model,GridProps,InputModel},true, signal),

DoCommandMulti: (controller: string, Command: string,Param: string,GridProps: GridProps,Record?: any,FieldName?: string,Value?: any,Url?: string, signal?: AbortSignal) => performAction(controller, "DoCommandMulti", {Command,Param,GridProps,Record,FieldName,Value,Url},true, signal),

GetFinalQuery: (controller: string, GridProps: GridProps,Context: any,Record?: any,Fields?: Set<string>,CountOnly?: boolean,DoFilterBars?: boolean,DoGrouping?: boolean,InputModel?: ControlProps[], signal?: AbortSignal) => performAction(controller, "GetFinalQuery", {GridProps,Context,Record,Fields,CountOnly,DoFilterBars,DoGrouping,InputModel},false, signal),

GetSuggestions: (controller: string, GridProps: GridProps, signal?: AbortSignal) => performActionT<string[]>(controller, "GetSuggestions", {GridProps},true, signal),

GetDisplayQuery: (controller: string, Context: any,GridProps: GridProps,Record?: any,CountOnly?: boolean,Fields?: Set<string>,DoFilterBars?: boolean,DoGrouping?: boolean,InputModel?: ControlProps[], signal?: AbortSignal) => performAction(controller, "GetDisplayQuery", {Context,GridProps,Record,CountOnly,Fields,DoFilterBars,DoGrouping,InputModel},false, signal),

GridKey: (controller: string, SubType?: any, signal?: AbortSignal) => performAction(controller, "GridKey", {SubType},false, signal),

OnActionExecuting: (controller: string, filterContext: any, signal?: AbortSignal) => performAction(controller, "OnActionExecuting", {filterContext},false, signal),

OnAuthorization: (controller: string, filterContext: any, signal?: AbortSignal) => performAction(controller, "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (controller: string, Input: any,DateFormat?: string,EnumStrings?: boolean, signal?: AbortSignal) => performAction(controller, "SafeJsonResult", {Input,DateFormat,EnumStrings},false, signal),

CommandResult: (controller: string, Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction(controller, "CommandResult", {Result,Context,Command},false, signal),

}
export interface Country{
  Name?: string;
  IsoCode?: string;
  Aliases?: string;
  States: any[];
  IsDefaultState: boolean;
  TempBulkField?: string;
  TempBulkIDs?: string;
  BadSaving: boolean;
  InvalidateCache: boolean;
  TrackChanges: boolean;
  ImportEdits: any[];
  WebSession?: any;
  Confirmed: boolean;
  WindowExtendedObject?: any;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export interface HomeData{
  Brands: MiniBrand[];
  Statements: MiniStatement[];
}

export interface LocalAddressExt{
  PersonName?: string;
  NameLine?: string;
  LastName?: string;
  FirstName?: string;
  Prefix?: string;
  NeutralPrefix?: string;
  NeutralFirstName?: string;
  NeutralLastName?: string;
  NeutralTitle?: string;
  NeutralNameLine?: string;
  NeutralName?: string;
  Phone?: number;
  FullLabel?: string;
  NeutralFullLabel?: string;
  PersonComment?: string;
  PersonID: number;
  Kind?: string;
  CityID?: number;
  CityName?: string;
  Zip?: string;
  Street?: string;
  House?: number;
  HouseName?: string;
  Floor?: number;
  Apt?: string;
  POBox?: number;
  Website?: string;
  IsPrivate: boolean;
  Caption?: string;
  StreetLine?: string;
  CityLine?: string;
  StateName?: string;
  CountryID?: number;
  CountryName?: string;
  Ordinal: number;
  CountryIso?: string;
  StateIso?: string;
  Attention?: string;
  TimeZone?: string;
  Entrance?: number;
  IsResidential: boolean;
  ServerData?: string;
  StateID?: number;
  ZipExt?: number;
  CountyName?: string;
  ForMailing: boolean;
  Longitude?: number;
  Latitude?: number;
  Verified: boolean;
  Label?: string;
  Name?: string;
  ClientVisible: boolean;
  AddressLine2?: string;
  AutoAttention: boolean;
  GooglePlaceCode?: string;
  VatCharge?: boolean;
  AddressSearch?: string;
  DelayedTill?: Date;
  Active: boolean;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export interface LocalSale{
  Status?: number;
  StatusDate?: Date;
  ShipWeight?: number;
  InsuranceValue?: number;
  ProductTotal: number;
  VatAmount: number;
  SubTotal: number;
  ShipTotal?: number;
  ItemCount: number;
  RequiredCapacity?: number;
  GrandTotal?: number;
  NotifyStatus?: number;
  NotifyStatusDate?: Date;
  TransportCharge?: number;
  TrackCode?: string;
  ProductCount: number;
  TempShipPersonID?: number;
  PaidAmount?: number;
  GatewayEnum?: number;
  AddedIP?: string;
  BillAddressID?: number;
  ShipAddressID?: number;
  ShipTypeID?: number;
  TaxExemptCode?: string;
  PlacedOn?: Date;
  AgentID?: number;
  StateVatRate: number;
  CityVatRate: number;
  CountyVatRate: number;
  TaxExemptType?: string;
  ShipZip?: string;
  InvoiceName?: string;
  DiscountRate: number;
  TotalVatRate?: number;
  InHouse: boolean;
  ProcessedOn?: Date;
  ProducerNotifiedOn?: Date;
  StartedOn: Date;
  UseTaxExempt: boolean;
  SendMails: boolean;
  ShipCounty?: string;
  CanceledOn?: Date;
  ShipCharge: number;
  ViaCart: boolean;
  CartZip?: string;
  InvoiceComment?: string;
  CartCountryID?: number;
  CartPayMethodID?: number;
  ShipChargeeID?: number;
  LetLacking: boolean;
  DeletedDeals?: string;
  DealArrangerID?: number;
  ShipPersonID?: number;
  SaleDate: Date;
  ShipTypeName?: string;
  ShipPersonName?: string;
  BillPersonName?: string;
  BillPersonID?: number;
  DealArrangerName?: string;
  ShipAddressLabel?: string;
  BillAddressLabel?: string;
  TaxCounty?: string;
  AutoPurchaseDate?: Date;
  GranteeID?: number;
  GranteeName?: string;
  GrantingDate?: Date;
  ForQuickProcess: boolean;
  AgentName?: string;
  CartCountryName?: string;
  ShipChargeeName?: string;
  ReceivedOn?: Date;
  TaxExemptBytes: string;
  CartPayMethodCardLast4?: number;
  ShipPersonComment?: string;
  ShipCityName?: string;
  ShipCityID?: number;
  NewAddress: boolean;
  ID: number;
  AddedOn: Date;
  AddedBy?: number;
  LastSeenOn?: Date;
  LastEditedOn?: Date;
  LastConfirmedOn?: Date;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  Comment?: string;
  LastConfirmedBy?: number;
  NameString?: string;
  InUserInterface: boolean;
  CheckValidations: boolean;
  IsInEditMode: boolean;
  CheckUniques: boolean;
}

export interface MiniBrand{
  ID: number;
  Name?: string;
  SubTitle?: string;
  Description?: string;
  CategoryID: number;
  Specifications?: string;
  Blurb?: string;
  Priority: number;
  Displays: MiniDisplay[];
}

export interface MiniDisplay{
  ID: number;
  Name?: string;
  FileType?: string;
}

export interface MiniPerson{
  ID: number;
  LastName?: string;
  FirstName?: string;
  CompanyName?: string;
  RoleID?: number;
  Email?: string;
  Number?: number;
  Cell?: number;
}

export interface MiniProduct{
  ID: number;
  BrandID: number;
  EditionRank: number;
  VersionLevel: number;
  OldReleaseID?: number;
  NewReleaseID?: number;
  SiteRealPrice?: number;
  SiteListPrice?: number;
  ExtendedName?: string;
  Brief?: string;
  BrandName?: string;
  Details?: string;
  Name?: string;
  Total: number;
  BrandParentID?: number;
  Priority?: number;
  DiskGroup?: number;
  InCart?: boolean;
}

export interface MiniSale{
  ID: number;
  GrandTotal?: number;
  ShipCharge: number;
  ProductTotal: number;
  ProductCount: number;
  Products: MiniProduct[];
  ShipTypeName?: string;
}

export interface MiniStatement{
  ID: number;
  Title?: string;
  Description?: string;
  Type: StatementTypes;
  Vector?: string;
  Ordinal: number;
}

export interface SiteData{
  User: MiniPerson;
  Phone: number;
  PhoneText?: string;
  Language?: string;
  Sale: MiniSale;
  Name?: string;
}

export enum StatementTypes {
    Question,
    Scroller,
    Reason,
    Citation,
}
export const LocalClient = {Public:{Search: (Term: string) => performAction("Public", "Search", {Term},false),

GetSiteData: () => performActionT<SiteData>("Public", "GetSiteData", {},false),

GetMainBrands: () => performActionT<MiniBrand[]>("Public", "GetMainBrands", {},false),

GetHomeData: () => performActionT<HomeData>("Public", "GetHomeData", {},false),

Contact: (model: ContactForm) => performAction("Public", "Contact", {model},true),

Categories: () => performActionT<{Key: number, Value: string}[]>("Public", "Categories", {},false),

Brands: (CategoryID?: number) => performActionT<MiniBrand[]>("Public", "Brands", {CategoryID},false),

News: () => performActionT<MiniStatement[]>("Public", "News", {},false),

SetPassword: (Email: string) => performAction("Public", "SetPassword", {Email},true),

Brand: (ID: number) => performActionT<MiniBrand>("Public", "Brand", {ID},false),

Products: (ID: number) => performActionT<MiniProduct[]>("Public", "Products", {ID},false),

Packages: (ID: number) => performActionT<MiniProduct[]>("Public", "Packages", {ID},false),

AddProducts: (IDs: number[]) => performActionT<MiniSale>("Public", "AddProducts", {IDs},true),

RemoveProduct: (Group: number,ProductID?: number) => performActionT<MiniSale>("Public", "RemoveProduct", {Group,ProductID},true),

EditCart: (DiskGroup: number,ToAdd: number[],ToRemove: number[]) => performActionT<MiniSale>("Public", "EditCart", {DiskGroup,ToAdd,ToRemove},true),

GetAddresses: () => performActionT<LocalAddressExt[]>("Public", "GetAddresses", {},false),

PersonDetails: (person: MiniPerson) => performAction("Public", "PersonDetails", {person},true),

SetAddress: (Id: number,IsBilling: boolean,UseForShipping: boolean) => performAction("Public", "SetAddress", {Id,IsBilling,UseForShipping},true),

SaveAddress: (addressData: AddressData) => performAction("Public", "SaveAddress", {addressData},true),

ShippingMethod: (model: any) => performAction("Public", "ShippingMethod", {model},false),

HideAddress: (ID: number) => performAction("Public", "HideAddress", {ID},false),

Pay: (Model: any) => performAction("Public", "Pay", {Model},true),

GetCartProducts: () => performAction("Public", "GetCartProducts", {},false),

CartProducts: () => performActionT<MiniProduct[]>("Public", "CartProducts", {},false),

GetSale: () => performAction("Public", "GetSale", {},false),

CartTotals: () => performActionT<MiniSale>("Public", "CartTotals", {},false),

ShippingRates: (CountryID?: number,Zip?: string) => performAction("Public", "ShippingRates", {CountryID,Zip},false),

GetSaleID: () => performAction("Public", "GetSaleID", {},false),

SetLanguage: (Language: string) => performAction("Public", "SetLanguage", {Language},false),

AddShipping: (ShipTypeID: number,Comment: string) => performAction("Public", "AddShipping", {ShipTypeID,Comment},false),

DiskGroupProducts: (DiskGroup: number) => performActionT<MiniProduct[]>("Public", "DiskGroupProducts", {DiskGroup},false),

GetCountries: () => performActionT<Country[]>("Public", "GetCountries", {},false),

IsEmailExists: (Email: string) => performActionT<boolean>("Public", "IsEmailExists", {Email},false),

EmailExists: (Email: string) => performAction("Public", "EmailExists", {Email},false),

CheckValidPassword: (password: string) => performAction("Public", "CheckValidPassword", {password},false),

IsCardValid: (cardNumber: string) => performAction("Public", "IsCardValid", {cardNumber},false),

}
}
//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***



export function queryVals(obj: any, inclueEmpties: boolean = false): string {
    let query: string | undefined;
    for (let k in obj) {
        let value = obj[k];
        if (value || inclueEmpties) {
            if (!value) value = '';
            let argument = `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
            if (query) query = query + '&' + argument;
            else query = argument;
        }
    }
    return query || '';
}

export async function fetchPlus(input: RequestInfo, init?: RequestInit) {
    let rsp = await fetch(input, init);
    if (rsp.status != 200) {
        const text = await rsp.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/xml");
        const ttl = doc.getElementsByTagName('title')[0].innerHTML;
        throw new Error(ttl);
    }
    return rsp
};

export function devLog(log: string, ...optionalParams: any[]) {
    //if (getServerData().InDebug) console.log(log, optionalParams)
      console.log(log, optionalParams)//cant check debugmode in shared.ts
      //console.log(log, optionalParams)
}



export async function fetchJson<T>(input: RequestInfo, init?: RequestInit) {
    //var rsp = await fetch(input, init);
    //if (rsp.status !== 200) {
    //    const text = await rsp.text();
    //    const parser = new DOMParser();
    //    const doc = parser.parseFromString(text, "text/xml");
    //    const ttl = doc.getElementsByTagName('title')[0].innerHTML;
    //    devLog('gonna throw reg err' + ttl, input)
    //    //alert(ttl)//this is wrong! but for now the error doesnt seem to be shown/bubbled up
    //    throw new Error(ttl);
    //}
    var rsp =await fetchPlus(input,init)
    var js = rsp.json();
    return js as Promise<T>;
};

export function groupBy<TItem, TKey extends keyof any>(
    xs: TItem[],
    key: (item: TItem) => TKey
): Partial<{ [P in TKey]: TItem[] | undefined }> {
    const res: Partial<{ [P in TKey]: TItem[] }> = {};
    return xs.reduce(function (rv, x) {
        const group = key(x);
        //HACK for some reason typescript doesn't detact the denullation, hence the "!"
        (rv[group] = rv[group] || new Array<TItem>())!.push(x);
        return rv;
    }, res);
}
