//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import NewAddressPopUp from './NewAddressPopUp';
import { AddressData } from '../Declarations';
interface BoxProps{
    newAddress:AddressData,
    handelSave:()=>void,
    handleChange:(address:AddressData)=>void, 
    isShipping:boolean
}
const NewAddressBox = (props:BoxProps  ) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const close=()=>{setShowPopUp(false)};
    return (
        <div style={{ borderWidth: '2px', borderColor: '#4c1518', borderStyle: 'dashed' }}>
            <div onClick={x => setShowPopUp(!showPopUp)} style={{ padding: '15px', cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center', height: '100%' }}>
                <PlusOutlined width={60} style={{ color: "#4c1518", fontSize: '55px' }} />
                <span style={{ color: "#4c1518", fontSize: '25px' }}>add new address</span>
            </div>
            {showPopUp ?
                <NewAddressPopUp
                    handleChange = {props.handleChange}
                    address={props.newAddress}
                    handleClose = {close}
                    onSave={props.handelSave}
                    isShipping={props.isShipping}
                /> : null}
        </div>
    )
}

export default NewAddressBox
