//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { serverUrl, currencyFormat } from '../../tools';
import { SyncOutlined } from '@ant-design/icons';



const dummyList = {
    rates: [
        {
            Key: 'fedex',
            Value: [
                {
                    Shipper: 0,
                    ShipTypeID: 31,
                    ShipCost: 5.0000,
                    InsuranceCost: 0.0,
                    ServiceName: 'USPS no tracking',
                    ServiceCode: 0,
                    ForCart: false,
                    TotalCost: 5.0000
                },
                {
                    Shipper: 0,
                    ShipTypeID: 51,
                    ShipCost: 0.0,
                    InsuranceCost: 0.0,
                    ServiceName: 'USPS',
                    ServiceCode: 0,
                    ForCart: false,
                    TotalCost: 0.0
                }
            ]
        }
        ,
        {
            Key: 'UPS',
            Value: [
                {
                    Shipper: 1,
                    ShipTypeID: 131,
                    ShipCost: 136.87,
                    InsuranceCost: 0.0,
                    ServiceName: 'UPS Saver',
                    ServiceCode: 65,
                    ForCart: false,
                    TotalCost: 136.87
                },
                {
                    Shipper: 1,
                    ShipTypeID: 161,
                    ShipCost: 131.55,
                    InsuranceCost: 0.0,
                    ServiceName: 'UPS Worldwide ExpeditedSM',
                    ServiceCode: 8,
                    ForCart: false,
                    TotalCost: 131.55
                },
                {
                    Shipper: 1,
                    ShipTypeID: 191,
                    ShipCost: 12.55,
                    InsuranceCost: 0.0,
                    ServiceName: 'Fedex Ground',
                    ServiceCode: 8,
                    ForCart: false,
                    TotalCost: 12.55
                }
            ]
        }
    ]
}






const ShippingRates = ({ shipTypeId, setShipTypeId, comment, setComment }) => {
    const [rates, setRates] = useState(null);

    useEffect(() => {
        const getRates = async () => {
            const { data } = await axios.get(serverUrl('ShippingRates'))
            console.log('rates: ', data);
            setRates(data)
        }
        // setRates(dummyList.rates)
        getRates();
    }, []);


    return (
        <>
            <h2>Shipping</h2>
            <div className="claShipmentOptions"  >
                <div style={{ width: '100%' }}>
                    {rates ? <> {
                        rates.map(x => {
                            return (<React.Fragment key={x.Key}>
                                <h5>{x.Key}</h5>
                                {x.List.map((q, i) => {
                                    return (
                                        <div key={q.ShipTypeID}>
                                            <input id={i} name="rates" type="radio" className="clsFirst" defaultChecked={shipTypeId === q.ShipTypeID} />
                                            <label htmlFor={i} className="clsRadioInput" onClick={x => setShipTypeId(q.ShipTypeID)}>{q.ServiceName} {currencyFormat(q.TotalCost)}</label>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                            )
                        })
                    }</>
                        : <div style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
                            <SyncOutlined style={{ fontSize: 50, color: '#b9792a' }} spin />
                            <span style={{ fontSize: '20px', fontWeight: "700", marginTop: '15px', color: '#b9792a' }}>getting shipping options..</span>
                        </div>}

                </div>
            </div>
            <div className="clsFild">
                <span>הערות</span>
                <textarea cols="999" value={comment} onChange={x => setComment(x.target.value)} placeholder="הערות על ההזמנה, הדגשות מיוחדות למשלוח"></textarea>
            </div>
        </>
    )
}

export default ShippingRates
