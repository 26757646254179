//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState } from 'react';
import '../style/contact-us.css'
import { message } from 'antd';
import * as m from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';

const newContactForm: m.ContactForm = { Name: '', Phone: '', Email: '', Message: '' }
interface dictionary { [key: string]: string }
const Contact = () => {
    const [form, setForm] = useState(newContactForm);
    const [isSending, setIsSending] = useState(false);
    const [validations, setValidations] = useState<Record<string, boolean>>({});
    const app = useContext(LocaleContext)!;

    // just some configurations ... 
    message.config({
        top: 200,
        rtl: true,
    });

    const handleOnChange = (fieldName: string, element: HTMLInputElement | HTMLTextAreaElement) => {
        setForm(prev => ({ ...prev, [fieldName]: element.value }))
        var errs = validations
        errs[fieldName] = element.validity.valid
        setValidations(errs)
    }

    const send = async () => {
        setIsSending(true);
        await m.LocalClient.Public.Contact(form!)
        message.success(app?.localized('Thank you for contacting us') + ', ' + app?.localized('we will get back to you shortly'));
        setForm(newContactForm)
        setIsSending(false);

    }

    var sd = app.data
    var vkeys = Object.keys(validations)
    var haserr = (!vkeys.length) || vkeys.some(x => !validations[x])

    return <section className="clsInsidePage">
        <div className="clsPageAlign">
            <form className="clsContactUs">
                <h1>{app?.localized('Contact')}</h1>
                <div style={{ display: 'flex', gap: 20 }}>
                    <div className="connectUsForm" style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <div>
                            <div className="clsFild clsSmallInput">
                                <span>{app?.localized('Full name')}</span>
                                <input type="text" value={form.Name} onChange={evt => handleOnChange('Name', evt.currentTarget)} />
                            </div>
                            <div className="clsFild clsSmallInput">
                                <span>{app?.localized('Phone')}</span>
                                <input type="text" value={form.Phone} onChange={evt => handleOnChange('Phone', evt.target)} />
                            </div>
                        </div>
                        <div className="clsFild clsLargeInput">
                            <span>{app?.localized('Email')}</span>
                            <input type="email" required pattern="[a-z0-9א-ת\.\?\+\/_&=-]+@[a-z0-9.-]+\.[a-z0-9]{2,}" value={form.Email} onChange={evt => handleOnChange('Email', evt.target)
                            } />
                        </div>
                        <div className="clsFild">
                            <span>{app?.localized('Message')}</span>
                            {/* <span style={{ color: 'red', fontWeight: 'bold' }}>{errors['message']}</span> */}
                            <textarea value={form.Message} onChange={evt => handleOnChange('Message', evt.target)}></textarea>
                        </div>
                        {/* <span style={{ color: 'red', fontWeight: 'bold', display: 'block', fontSize: '19.5px' }}>{errors['global']}</span> */}
                        <button type="button" onClick={e => send()} disabled={isSending || haserr} style={isSending ? { cursor: 'progress' } : {}}>
                            {app?.localized(isSending ? 'Sending' : 'Send')}
                            {isSending && <i className="fa fa-spinner fa-spin" style={{ padding: '15px' }} ></i>}
                        </button>
                    </div>
                    {sd && <div className="clsConnectUsInfo" style={{ display: 'inline-flex', flexDirection: 'column', gap: 8, direction: "ltr" }}>
                        <h2 style={{ fontWeight: 'bold', color: "#4c1518" }}>{sd.Name}</h2>
                        <div>
                            <span style={{ width: 30, textAlign: 'left' }}><i className="fa fa-phone" style={{ color: "#4c1518" }} aria-hidden="true"></i></span>
                            <a href={`tel:${sd?.Phone}`} style={{ display: 'inline-block' }}>{sd.PhoneText}</a>
                        </div>
                        <div>
                            <span style={{ width: 30, textAlign: 'left' }}><i className="fa fa-map-marker" style={{ color: "#4c1518", verticalAlign: 'super' }} aria-hidden="true"></i></span>
                            <a href="https://goo.gl/maps/6VaFSfYNctyaZCt29" target='_blank' style={{ display: 'inline-block' }} rel='noopener noreferrer'>
                                <p>45 Lee Ave. 2 FL. <br />Brooklyn NY 11211</p>
                            </a>
                        </div>
                        <div>
                            <span style={{ width: 30, textAlign: 'left' }}><i className="fa fa-envelope" style={{ color: "#4c1518" }} aria-hidden="true"></i></span>
                            <a href="mailto:info@judaicasoftware.com" style={{ display: 'inline-block' }}>info@judaicasoftware.com</a>
                        </div>
                    </div>}
                </div>

            </form>
        </div>
    </section>
}
export default Contact;