//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react'
import * as m from '../Declarations'

interface AddressBoxProps{
    address:m.LocalAddressExt,
    handelSelect:(id:number)=>void, 
    handelDelete:(id:number)=>void, 
    isSelected:boolean
}

const AddressBox = (props:AddressBoxProps) => {
    const {ID, NeutralName, Attention, StreetLine, CityLine, CountryName } = props.address
    return (
        <div
            style={{  padding: '15px', boxShadow: props.isSelected ? '0 0 8px 3px rgba(0, 0, 0, 0.35)' : '', display: "flex", flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}
            className='clsAddressItem clsSubItem clsText'>
            {props.isSelected ? <div style={{ position: 'absolute', left: -13,top:-13,fontSize:'34px', background: 'linear-gradient(53deg, #b9792a, #f5d070 45%, #b9792a 84%), linear-gradient(#b9792a, #b9792a)', height: '60px', width: '60px', borderRadius: '35px', textAlign: 'center', paddingTop: '20px' }}>✔</div> : null}
            <div>
                <div>{NeutralName}</div>
                <div>{Attention}</div>
                <div>{StreetLine}</div>
                <div>{CityLine}</div>
                <div>{CountryName}</div>
            </div>
            <div style={{ display: 'flex', flexWrap: "wrap" }}>
                <input type='button' onClick={e=>props.handelSelect(ID)} value='Use This Address' style={{ flexGrow: 1, width: 'unset', height: 'unset', padding: '8px', fontSize: '18px', fontWeight: 'bold', marginTop: '60px' }}></input>
                <input type='button' onClick={e=>props.handelDelete(ID)} value='Delete this Address' style={{ flexGrow: 1, width: 'unset', height: 'unset', padding: '8px', fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}></input>
            </div>
        </div>
    )
}

export default AddressBox
