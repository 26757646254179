//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { createContext, useEffect, useState, useRef, PropsWithChildren } from 'react'
import * as m from '../Declarations';
import { dePascalCase } from '../general';

type LocaleContextType = {
    changeLanguage: () => Promise<void>;
    setSale: (input: m.MiniSale) => void;
    localized: (value: string) => string;
    dictionary: { [key: string]: string },
    data?: m.SiteData
    login: (email: string, password: string) => Promise<boolean>,
    errorMessage?: string,
    signOut: () => void
};

export const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

const LocaleContextProvider = (props: PropsWithChildren<any>) => {
    useEffect(() => {
        getSiteData()
        document.addEventListener('mousemove', keepSessionLive)
        return () => { document.removeEventListener('mousemove', keepSessionLive) }
    }, []);

    const setErrorMessage = (message: string) => {
        setState(pr => ({ ...pr!, errorMessage: message }));
    }
    const changeLanguage = async () => {
        const lang = state.data?.Language === 'HE' ? 'EN' : 'HE';
        setLanguage(lang);
    }

    const setLanguage = async (lang: string) => {
        document.dir = lang === 'HE' ? 'rtl' : 'ltr';
        await m.LocalClient.Public.SetLanguage(lang)
        const newDictionary = await getUiText(lang, false);
        //setState(pre => ({ ...pre!, dictionary: newDictionary, data: { ...pre.data!, Language: lang } }));
        var sal = await m.LocalClient.Public.CartTotals()
        setState(prev => ({ ...prev!, dictionary: newDictionary, data: { ...prev.data!, Language: lang, Sale: sal } }));
    }
    function setSale(input: m.MiniSale) {
        setState(prev => ({ ...prev!, data: { ...prev.data!, Sale: input } }));
    }

    const localized = (text: string) => {
        if ((timeStamp.current + 600000) < Date.now()) { // every 10 minut we fetch new uitext from server
            timeStamp.current = Date.now();
            if (state?.data?.Language) load(state?.data?.Language, true)
        }
        let value = "";
        var dc = state?.dictionary;
        if (dc && Object.keys(dc!).length) {
            if (dc!.hasOwnProperty(text)) {
                value = dc![text];
            } else {
                m.MachshevetClient.Global.Localize(text)
            }
        }
        return value || dePascalCase(text);
    }

    const timeStamp = useRef(Date.now());

    async function getUiText(language: string, reCache: boolean) {
        const cached = localStorage.getItem(`uiText_${language}`);
        if (cached && !reCache) {
            var js: { [index: string]: string } = JSON.parse(cached);
            return js
        } else {
            const data = await m.MachshevetClient.Global.UITexts(language)
            localStorage.setItem(`uiText_${language}`, JSON.stringify(data));
            return data;
        }
    }

    const load = async (language: string, reCache: boolean) => {
        const res = await getUiText(language, reCache);
        setState(prev => ({ ...prev!, dictionary: res }))
    };

    const lastSessionPing = useRef(Date.now() - 100000);

    const keepSessionLive = () => {
        if (Date.now() - lastSessionPing.current > (120 * 1000)) {
            lastSessionPing.current = Date.now();
            //axios.get(serverUrlLogin('KeepSessionLive'), { withCredentials: true })
            m.MachshevetClient.Login.KeepSessionLive()
        }
    }
    const getSiteData = async () => {
        const data = await m.LocalClient.Public.GetSiteData()
        //if (data) {
        setState(pr => ({ ...pr!, data: data }))
        //}
    }

    const logOut = async () => {
        await m.MachshevetClient.Login.LogOut()
        //setState(pr => ({ ...pr!, data: { ...pr.data!, user: undefined } }))
        getSiteData()
    };

    const logIn = async (email: string, password: string) => {
        const result = await m.MachshevetClient.Login.Login(email, password)
        if (result.Value) {
            setErrorMessage(result.Value);
            setTimeout(() => setErrorMessage(''), 5000);
            return false;
        } else {
            getSiteData()
            setErrorMessage('');
            return true;
        }
    };

    const [state, setState] = useState<LocaleContextType>({ changeLanguage: changeLanguage, localized: localized, dictionary: {}, login: logIn, signOut: logOut, setSale: setSale })
    useEffect(() => {
        //setLanguage(state?.data?.Language!)
        document.dir = state?.data?.Language === 'HE' ? 'rtl' : 'ltr';
    }, [state?.data?.Language])

    return <LocaleContext.Provider value={{ changeLanguage, localized, dictionary: state.dictionary, data: state.data, login: logIn, errorMessage: state.errorMessage, signOut: logOut, setSale }}>
        {props.children}
    </LocaleContext.Provider>
}

export default LocaleContextProvider