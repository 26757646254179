//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useEffect, useState } from 'react';
import { Carousel, Collapse, ConfigProvider } from 'antd';
import * as m from '../../Declarations';
import { Link } from 'react-router-dom';
import './info.css';
import '../../style/home.css'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { LocaleContext } from '../../Contexts/LocaleContext';
import { BrandAdmin, BrandImage, StatementAdmin, StatementImage } from '../../misc';

const settings = {
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 8000,
};

const infosettings = {
    dots: false,
    className: 'clsTestemonialsBox',
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 8000,
}


const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 8000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
}

const Home: FC = props => {
    const [state, setState] = useState<m.HomeData>();
    const app = useContext(LocaleContext);

    useEffect(() => {
        getBrands();
    }, []);

    useEffect(() => {
        getBrands();
    }, [app?.data?.Language]);

    const getBrands = async () => {
        const result = await m.LocalClient.Public.GetHomeData()
        setState(result);
    }

    const { Panel } = Collapse;
    const borderStyle = { borderBottom: 'solid 1px #ede6de' };
    const header = (text: string) => {
        return <h3 className='clsQuestionHeader' >{text}</h3>
    }
    const iconStyle = {
        color: '#4c1518',
        fontSize: '18px'
    }
    var slides = state && state.Statements.filter(x => x.Type === m.StatementTypes.Scroller);
    var questions = state && state.Statements.filter(x => x.Type === m.StatementTypes.Question);
    var elms = slides && slides.map(x => <div>{x}</div>)

    return <div>
        <ConfigProvider direction={document.dir === "ltr" ? "ltr" : "rtl"}>
            <div className='clsInsidePage'>
                <Carousel {...settings} >
                    {slides && slides.sortBy(x => x.Ordinal).map(x =>
                        <div key={x.ID} >
                            <StatementAdmin {...x} />
                            <div className="clsGalleryContent" style={{ padding: 50 }} >
                                <div className="clsPageAlign">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ padding: 20 }}>
                                            <h2>{x.Title}</h2>
                                            <p>{x.Description}</p>
                                        </div>
                                        <StatementImage id={x.ID} name={x.Title!} style={{ maxHeight: 350, padding: 20 }} /></div>
                                    <Link to={'brands'}>{app?.localized("ToAllOurProducts")}</Link>
                                </div>
                            </div>
                        </div>)}
                </Carousel>
            </div>
            <div className="clsRecItems">
                <div className="clsPageAlign">
                    <section>
                        <Carousel {...sliderSettings} arrows>
                            {state?.Brands.map(x => {
                                return <div className="clsProduct" key={x.ID}>
                                    {app?.data?.User && app.data.User.RoleID && <BrandAdmin {...x} />}
                                    <div className="clsImg">
                                        <BrandImage id={x.ID} name={x.Name!} style={{ maxHeight: 200, maxWidth: 230 }} />
                                        <p style={{ width: '100%', height: '100%' }}>{x.SubTitle}<br />
                                            <Link to={`brand/${x.ID}`} style={{ textAlign: 'center' }}>לפרטים ולרכישה</Link>
                                        </p>
                                    </div>
                                    <h3>{x.Name}</h3>
                                </div>
                            })}
                        </Carousel>
                    </section>
                    <Link to={'brands'}>{app?.localized("ToAllPrograms")}</Link>
                </div>
            </div>
            <section className='clsInfo'>
                <div className='container'>
                    <div className='item1'>
                        <section className="clsReasonsSide">
                            <h2>כל הסיבות לרכוש דוקא ב Judaica Software</h2>
                            {state?.Statements.filter(x => x.Type === m.StatementTypes.Reason).map(x => <div key={x.ID} className="clsReason" style={{ display: 'flex' }}>
                                <svg dangerouslySetInnerHTML={{ __html: x.Vector! }} style={{ height: 55 }} />
                                <div>
                                    <div className="clsText">
                                        <h3>{x.Title}</h3>{x.Description}
                                    </div>
                                </div>
                            </div>)}
                        </section>
                    </div>
                    <div className='item2 '>
                        <section className="clsQuestionsSide">
                            <h2>{app?.localized("FrequentlyAskedQuestions")}</h2>
                            <Collapse
                                expandIcon={({ isActive }) => isActive ? <MinusOutlined style={iconStyle} /> : <PlusOutlined style={iconStyle} />}
                                // defaultActiveKey={['1']}
                                ghost accordion>
                                {questions?.map(x => <Panel header={header(x.Title!)} key={x.Title!} style={borderStyle}>
                                    <div className="clsText clsAccordionContent" >{x.Description}</div>
                                </Panel>)}
                            </Collapse>
                        </section>
                    </div>
                    <div className='item3'>
                        <Carousel {...infosettings} >
                            {state?.Statements.filter(x => x.Type === m.StatementTypes.Citation).map(x => <div className="clsClinetRec">
                                <p>{x.Description}<br /><span>{x.Title}</span></p>
                            </div>)}
                        </Carousel>
                    </div>
                    <Link className="clsLink" to={'about'}>{app?.localized("MoreAboutUs")}</Link>
                </div>
            </section>


        </ConfigProvider>
    </div>
}
export default Home;