//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react'
import '../../style/confirm.css' 
import GiftDetails from './GiftDetails'

const Confirm = ({saleId}) => {
    return (
        <div className="clsPurchaseAccepted">
                <section className="clsBox">
                    <h1>הזמנתך התקבלה!</h1>
                    <h3>מס' הזמנה: {saleId}</h3>
                    <p className="clsText">
                        תודה רבה על הזמנתך. ברגעים אלו ממש הזמנתך התקבלה במערכת ומטופלת ע"י אחד מנציגינו.חשבונית הרכישה נשלחה לדואר האלקטרוני שלך.
                    </p>
                </section>
                <GiftDetails/>
            </div>
    )
}

export default Confirm
