//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useEffect, ChangeEvent, useContext } from 'react'
import { SyncOutlined } from '@ant-design/icons';
import { currencyFormat } from '../tools'
import * as m from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';
import { Popup } from '../misc';

const EditCartPopUp: FC<{ diskGroup: number, onClose: () => void }> = props => {
    const app = useContext(LocaleContext);
    const [loading, setLoading] = useState(true);
    const [parentProduct, setParentProduct] = useState<m.MiniProduct>();
    const [packages, setPackages] = useState<m.MiniProduct[]>([]);
    const [toAddProducts, setToAddProducts] = useState<number[]>([]);
    const [toRemoveProducts, setToRemvoeProducts] = useState<number[]>([]);
    const handelClick = (event: ChangeEvent<HTMLInputElement>, productId: number) => {
        const { checked } = event.target;

        let toAdd;
        let toRemove;

        if (checked) {
            toAdd = [...toAddProducts, productId];
            toRemove = toRemoveProducts.filter((x) => x !== productId);
        } else {
            toRemove = [...toRemoveProducts, productId];
            toAdd = toAddProducts.filter((x) => x !== productId);
        }
        setToAddProducts(toAdd);
        setToRemvoeProducts(toRemove);
    };

    const total = () => {
        let sum = packages.map(x => (toAddProducts.find(q => q === x.ID) ? x.SiteRealPrice : 0)).reduce((r, i) => r! + i!, 0)!;
        sum += parentProduct!.SiteRealPrice!;
        return sum;
    }

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const products = await m.LocalClient.Public.DiskGroupProducts(props.diskGroup)

            const ProductIDs = products.filter(x => x.InCart).map(x => x.ID)
            setToAddProducts(ProductIDs)

            const remove = products.filter(x => !x.InCart).map(x => x.ID)
            setToRemvoeProducts(remove)

            setParentProduct(products.find(x => x.BrandParentID === null))
            setPackages(products.filter(x => x.BrandParentID !== null))

            setLoading(false);
        };
        if (props.diskGroup) getData();
    }, [props.diskGroup]);

    return <Popup title={app?.localized('Edit')} onClose={props.onClose} onOkay={async () => {
        var sal = await m.LocalClient.Public.EditCart(props.diskGroup, toAddProducts, toRemoveProducts);
        app?.setSale(sal)
        // app.refreshcart()
        // sal.setTotals()
    }} >
        {loading ?
            <div style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
                <SyncOutlined style={{ fontSize: 50, color: '#b9792a' }} spin />
                <span style={{ fontSize: '16px', fontWeight: "bold" }}>one moment please</span>
            </div>
            :
            <div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <p style={{ color: '#4c1518', fontSize: '23px', paddingBottom: '1em' }}>{parentProduct?.Name}</p>
                    <span style={{ color: '#b9792a', fontSize: '23px' }}>{currencyFormat(parentProduct?.SiteRealPrice)}</span>
                </div>
                <div style={{ display: "flex", flexDirection: 'column' }}>

                    {packages.map(p => {
                        return <div style={{ display: "flex", justifyContent: 'space-between' }} key={p.ID}>
                            <label htmlFor={p.ID.toString()} >
                                <input
                                    type="checkbox"
                                    id={p.ID.toString()}
                                    defaultChecked={toAddProducts.some(x => x === p.ID)}
                                    onChange={(e) => handelClick(e, p.ID)} />
                                <span style={{ color: '#b9792a', fontSize: '20px' }}>{p.Name}</span>
                            </label>
                            <span style={{ color: '#b9792a', fontSize: '20px' }}>{currencyFormat(p.SiteRealPrice)}</span>
                        </div>
                    })}
                </div>
                <p style={{ color: ' #831518', fontSize: '28px', fontWeight: 'bold', textAlign: 'center', paddingTop: '3vh' }}> {currencyFormat(total())}</p>
            </div>
        }
    </Popup>
}

export default EditCartPopUp
