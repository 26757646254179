//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState, useEffect } from 'react';
import '../style/purchase.css';
import PurchaseSteps from './PurchaseSteps';
import { currencyFormat, actions } from '../tools';
import { Link, useHistory } from 'react-router-dom';
//import { SaleContext } from '../Contexts/SaleContext';
import { groupBy, MiniProduct } from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';

const Purchase = () => {
    const history = useHistory();
    //const { state, dispatch } = useContext(SaleContext)
    const app = useContext(LocaleContext)
    const [showLogin, setShowLogin] = useState(false);
    const [loginUserName, setLoginUserName] = useState('');
    const [password, setPassword] = useState('');
    const [IsLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() => {
        if (app?.data?.Sale?.ProductCount! > 0 && app?.data?.Sale?.Products.length === 0) {
            //dispatch({ type: actions.SET_PRODUCTS, payLoad: undefined });
        }
    }, [app?.data?.Sale?.ProductCount]);
    function setShipping() {
        //dispatch({ type: actions.ADD_SHIPPING, payLoad: id })
    }
    const login = async () => {
        setIsLoggingIn(true);
        await app?.login(loginUserName, password);
        setIsLoggingIn(false);
        setShowLogin(false);
    }

    const groups = app?.data?.Sale?.Products && groupBy<MiniProduct, string>(app.data?.Sale.Products, x => x.DiskGroup!.toString());
    return (
        <section className="clsInsidePage">
            <div className="clsPageAlign" >
                <h1>קופה</h1>
                {!app?.data?.User?.ID && <div className="clsConnectToWebsiteHead">
                    עוד לא מחובר? <span className="clsBold" onClick={x => setShowLogin(!showLogin)}>התחבר עכשיו</span>
                </div>}
                {(!app?.data?.User?.ID && showLogin) &&
                    <div className="clsConnectToWebsite" >
                        <div className="clsFild">
                            <span>שם משתמש (דוא"ל) *</span>
                            <input type="text" value={loginUserName} onChange={e => setLoginUserName(e.target.value)} />
                        </div>
                        <div className="clsFild">
                            <span>סיסמא *</span>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <hr />
                        {app?.errorMessage && <span style={{ fontSize: '25px', color: 'red', display: 'block', marginBottom: 'inherit' }}>{app.errorMessage}</span>}
                        <button onClick={login}>
                            התחבר
                            {IsLoggingIn && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '-12px', marginRight: '15px', color: 'white' }} />}
                        </button>
                        <div className="clsBox">
                            <Link to="/connect/setPassword?return=purchase">שכחת את הסיסמא?</Link>
                        </div>
                    </div>}
                <div className="clsPurchasePage">

                    <div className="clsPurchaseform">
                        <PurchaseSteps HandleSubmit={setShipping} />
                    </div>
                    {/* <aside className="clsPurchaseformSummary" style={{ position: "absolute" }}> */}
                    <aside className="clsPurchaseformSummary"   >
                        {/* {state?.loading && <div className="lmask"></div>} */}
                        <h2>ההזמנה שלך</h2>
                        <div className="clsHeadingLine">
                            <span>מוצר</span>
                            <span>סה"כ</span>
                        </div>
                        {groups && Object.keys(groups).map((g) => {
                            const prnt = groups[g]?.find(x => x.BrandParentID === null);
                            const pckgs = groups[g]?.filter(x => x.BrandParentID !== null);
                            const price = (pckgs?.length ? pckgs.map(x => x.Total).reduce((a, c) => a + c) : 0) + prnt!.Total;
                            return (
                                <div className="clsSubLine" key={prnt!.Name}>
                                    <div className="clsProductName">
                                        <h4>{prnt!.Name}</h4>
                                        {pckgs?.map(q => <div key={q.Name}>{q.Name}</div>)}
                                    </div>
                                    <span>{currencyFormat(price)}</span>
                                </div>)
                        })}

                        <div className="clsHeadingLine">
                            <span>סה"כ ביניים</span>
                            <span>{currencyFormat(app?.data?.Sale?.ProductTotal)}</span>
                        </div>

                        <div className="clsHeadingLine">
                            <span>משלוח</span>
                            <span>{currencyFormat(app?.data?.Sale?.ShipCharge)}</span>
                        </div>

                        <p>{app?.data?.Sale?.ShipTypeName ? app.data?.Sale.ShipTypeName : 'No shipping was chosen'}</p>                        {/* <p>Priority Mail - 1-3 days $10.30<br /><span className="clsNormalFont">בשביל לשנות את סוג המשלוח  <a href="javascript:fChangeAddress()">יש לחזור לסל הקניות</a></span></p> */}
                        <div className="clsHeadingLine">
                            <span>סה"כ</span>
                            <span className="clsBold">{currencyFormat(app?.data?.Sale?.GrandTotal)}</span>
                        </div>
                        <input disabled={!app?.data?.Sale?.ShipTypeName} type="button" value="לתשלום"
                            onClick={e => {
                                e.preventDefault();
                                history.push('/pay')
                            }} />
                    </aside>
                </div>
            </div>
        </section>
    )
}

export default Purchase
