//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LocaleContext } from '../Contexts/LocaleContext';
import '../style/home.css'
import axios from 'axios';
import { serverUrl } from '../tools';
import { SearchResultBox } from '../misc';
//import SearchResultBox from './Search/SearchResultBox';

const TopBar = () => {
    const app = useContext(LocaleContext)
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [SearchResult, setSearchResult] = useState([]);
    const [ShowResults, setShowResults] = useState<boolean>(false);

    useEffect(() => {
        const search = async () => {
            const { data } = await axios.get(serverUrl('Search'), { params: { Term: searchTerm } })
            if (data.length) {
                setShowResults(true)
                setSearchResult(data);
            } else {
                setShowResults(false)
                setSearchResult([]);
            }
        }
        if (searchTerm.length >= 3 || SearchResult.length) search();
    }, [searchTerm]);

    const loginData = () => {
        if (app?.data?.User?.ID) {
            const direction = document.dir;
            const stl: React.CSSProperties = {
                float: direction === 'rtl' ? 'left' : 'right',
                marginLeft: direction === 'rtl' ? '44px' : 0,
                marginRight: direction === 'rtl' ? 0 : '44px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'baseline',
                width: '10em',
                fontWeight: 700,
                background: '#f5deb3'
            }
            return (
                <div style={stl}>
                    <span>{app.data.User.FirstName} {app.data.User.LastName}</span>
                    <span style={{ lineHeight: '36px', cursor: 'pointer' }} onClick={app.signOut}>{app.localized('LogOut')}</span>
                </div>)
        } else {
            return <Link to="/connect">{app?.localized('Login')}</Link>
        }
    }
    return <div className="clsTopBar">
        <div className="clsPageAlign" style={{ width: 1170, margin: "0 auto" }}>
            <span>בס"ד</span>
            <div className="clsSearchInput">
                <form method="get" action="search">
                    <input type="text" name="q" className="clsFormElements" placeholder={app?.localized('Search')} value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} />
                    <span><i className="fa fa-search" aria-hidden="true"></i></span>
                </form>
                <SearchResultBox products={SearchResult} visible={ShowResults} close={() => setShowResults(false)} term={searchTerm} />
            </div>
            <div className="clsLanguagesgLink">
                <input type='button' value={app?.data?.Language === 'HE' ? 'ENGLISH' : 'עברית'}
                    onClick={e => app?.changeLanguage()} />
                <i className="fa fa-globe" aria-hidden="true"></i>
            </div>
            {loginData()}
        </div>
    </div >
}

export default TopBar;