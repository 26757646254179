//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState } from 'react';
import { currencyFormat } from '../../tools'
import * as m from '../../Declarations';
import { ProductAdmin } from '../../misc';


const PackagesList: FC<{ packages: m.MiniProduct[] | undefined, onClick: any }> = props => {
    const [showDetailsId, setShowDetailsId] = useState<number>();

    const getSettings = (index: number) => {
        if (index === showDetailsId) {
            return {
                arrowClass: 'fa fa-angle-down',
                style: { display: 'block', maxHeight: '25em', overflow: 'auto', transition: 'max-height 0.5s, overflow 0.5s 0.5s' }
            }
        }
        return {
            arrowClass: 'fa fa-angle-up',
            style: { display: 'block', maxHeight: 0, overflow: 'hidden', transition: 'max-height 0.5s, overflow 0s', paddingTop: '0px' }
        }
    }

    return (<ul style={{ display: 'block' }}>
        {props.packages?.map((p, i) => {
            const detailsSettings = getSettings(i);
            var sID = p.ID.toString()
            return <li key={p.ID}><ProductAdmin {...p} />
                <input id={sID} type="checkbox" name="one" onClick={(evt) => props.onClick(evt, p)} />
                <label htmlFor={sID}>&nbsp;&nbsp;&nbsp;</label>
                <span onClick={x => setShowDetailsId(prev => prev === i ? undefined : i)} className="arrow"  ><span>{p.Name}</span>&nbsp;&nbsp;<span><i className={detailsSettings.arrowClass} aria-hidden="true"></i></span></span>
                <span style={{ margin: '45px', fontWeight: 600 }}>{currencyFormat(p.SiteRealPrice)}</span>
                <p style={detailsSettings.style}>{p.Brief}</p>
            </li>

        })}
    </ul>
    );
}

export default PackagesList;

