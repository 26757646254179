//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import * as React from 'react';
import { useState, FC, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { LocaleContext } from './Contexts/LocaleContext';
import { MachshevetClient, MiniBrand, MiniProduct, MiniStatement } from './Declarations';
import { currencyFormat, serverBaseUrl, serverUrl, serverUrlGlobal } from './tools';
import { Skeleton } from 'antd';
import Logo from "./images/headerLogo.png"

interface VecIconProps extends React.HTMLAttributes<HTMLSpanElement> { name: string, width?: number, color?: string, position?: 'absolute', backColor?: string }
export const VecIcon: FC<VecIconProps> = props => {
    const [svg, setSvg] = useState<string>();
    useEffect(() => {
        (async () => {
            const res = await MachshevetClient.Global.VectorIcon(props.name);
            var text = await res.text();
            if (text !== '') {
                setSvg(text);
            }
        })();
    }, [props.name])

    const svg2 = svg || '<svg viewBox="0 0 512 512" ><path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0z  M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133 S477.867,133.663,477.867,256S378.337,477.867,256,477.867z" /></svg>'
    const wdt = props.width || 32
    const colr = props.color || "currentColor"
    const bcolr = props.backColor || undefined
    return <span {...props} dangerouslySetInnerHTML={{ __html: svg2 }} style={{ width: wdt, display: "inline-block", fill: colr, stroke: colr, position: props.position, backgroundColor: bcolr }} />
}

export const EditButton: FC<{ RecordType: string, RecordID: number }> = props => {
    const myUrl = window.location.href//.replaceAll("/",";")
    const app = useContext(LocaleContext);
    return app && app.data?.User && app.data?.User.RoleID ?
        <a href={serverBaseUrl() + "?PublicUrl=" + myUrl + '/#/' + props.RecordType + '/Edit/' + props.RecordID}>
            <VecIcon name="Edit" width={18} />
        </a> : <></>
}
export const ProductAdmin: FC<MiniProduct> = props => {
    return <EditButton RecordType="Product" RecordID={props.ID} />
}
export const StatementAdmin: FC<MiniStatement> = props => {
    return <EditButton RecordType="Statement" RecordID={props.ID} />
}
export const BrandAdmin: FC<MiniBrand> = props => {
    return <EditButton RecordType="Brand" RecordID={props.ID} />
}

export const Popup: FC<{ title?: string, onClose: () => void, onOkay: () => void }> = props => {
    const app = useContext(LocaleContext);
    return <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgba(0,0,0,.45)" }}>
        <div style={{ backgroundColor: 'white' }}>
            <div style={{ backgroundColor: "#d8b775", display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 22, }}>
                <div style={{ padding: 10 }} >{props.title}</div>
                <span style={{ padding: 10, cursor: 'pointer' }} onClick={props.onClose}>X</span>
            </div>
            <div style={{ padding: 20 }}> {props.children}</div>
            <div style={{ backgroundColor: "#d8b775", padding: 10 }} ><button type='button' onClick={() => {
                props.onOkay()
                props.onClose()
            }} >{app!.localized("Okay")}</button></div>
        </div>
    </div>
}

const SearchItem: FC<MiniProduct> = props => {
    return <div className="clsResult">
        <Link to={`/brand/${props.BrandID}`} style={{ display: 'flex' }} >
            <div className="clsImg">
                <BrandImage id={props.BrandID} name={props.BrandName!} />
            </div>
            <div className="clsResultInfo">
                <span>{props.ExtendedName}</span>
                <hr />
                <span>Price: {currencyFormat(props.SiteRealPrice)}</span>
            </div>
        </Link>
    </div>

}

export const SearchResultBox: FC<{ products: MiniProduct[], term: string, visible: boolean, close: () => void }> = props => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref) {
            // close if clicked on outside of element
            const handleClickOutside = (event: MouseEvent) => {
                // if (ref.current && !ref.current.contains(event.target)) {
                //     close()
                // }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [ref]);

    return <div ref={ref} className="clsSearchResults" style={{ display: props.visible ? 'inline-block' : 'none' }}>
        <div className="clsResultBox">
            <div className="clsBox">
                <h2>תוצאות קטלוג מוצרים</h2>
                <span>{props.products.length} results</span>
                <hr />
                {props.products.map(p => <SearchItem key={p.ID} {...p} />)}
            </div>
        </div>
        <Link to="/Brands">Show all results for "{props.term}"</Link>
    </div>
}

const BrandBox: FC<MiniBrand> = props => {
    if (props) {
        return <div className="clsItem" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}  >
            <BrandAdmin {...props} />
            <BrandImage id={props.ID} name={props.Name!} />
            {props.Blurb && <div className="clsSale">{props.Blurb}</div>}
            <div className="clsText" style={{ padding: 24 }}>
                <h3>{props.Name}</h3>
                <p>{props.SubTitle}</p>
                <Link to={`/brand/${props.ID}`} style={{ backgroundColor: "#4c1518", color: "white" }}>לפרטים ורכישה</Link>
            </div>
        </div>
    }
    else {
        return <div className='clsItem' style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
            <Skeleton.Image></Skeleton.Image>
            <Skeleton active></Skeleton>
            <Skeleton.Button active></Skeleton.Button>
        </div>
    }
}
export default BrandBox;


export const RecordImage: FC<{ recordType: string, id: number, name: string, style?: React.CSSProperties, column?: string }> = props => {
    //https://judaicasoftware.com:770/Brand/GetDoc/204?Column=ImageBytes
    //serverBaseUrl()
    //return <img src={serverUrl(`${props.recordType}/GetDoc/${props.id}?Column=${props.column || "Image"}`).replace('/public','')} alt={props.name} style={props.style} onError={e => e.currentTarget.src = Logo} />
    return <img src={serverBaseUrl() + `/${props.recordType}/GetDoc/${props.id}?Column=${props.column || "Image"}`}
        alt={props.name} style={props.style} onError={e => e.currentTarget.src = Logo} />
}
export const BrandImage: FC<{ id: number, name: string, style?: React.CSSProperties }> = props => {
    return <RecordImage {...props} recordType='Brand' column='ImageBytes' />
}
export const ProductImage: FC<{ id: number, name: string, style?: React.CSSProperties }> = props => {
    return <RecordImage {...props} recordType='Product' column='ImageBytes' />
}
export const DisplayImage: FC<{ id: number, name: string, style?: React.CSSProperties }> = props => {
    return <RecordImage {...props} recordType='Display' column='FileBytes' />
}
export const StatementImage: FC<{ id: number, name: string, style?: React.CSSProperties }> = props => {
    return <RecordImage {...props} recordType='Statement' />
}