//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext } from 'react'
import '../style/CartProducts.css';
import CartItem from './CartItem';
import { Link } from "react-router-dom";
import { groupBy, MiniProduct } from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';

const CartProducts: FC = props => {
    const app = useContext(LocaleContext)!;
    var sal = app.data?.Sale
    const groups = sal?.Products && groupBy<MiniProduct, string>(sal.Products, x => x.DiskGroup!.toString());

    return <table style={{ width: '100%' }}>
        <thead>
            <tr style={{ height: '46px', borderBottom: '1px solid #b9792a' }}>
                <th></th>
                <th>{app!.localized("Product")}</th>
                <th></th>
                <th>{app!.localized("Price")}</th>
                <th>{app!.localized("Quantity")}</th>
                <th>{app!.localized("Total")}</th>
            </tr>
        </thead>
        <tbody>
            {!groups ?
                <tr>
                    <td colSpan={6} style={{ paddingBottom: '75px' }} >
                        <div>
                            <h3 style={{ marginTop: '85px', marginBottom: '20px', textAlign: 'center', float: 'none' }}>cart is empty</h3>
                            <Link to="/brands"><h3 style={{ textAlign: 'center', float: 'none', fontSize: '22px', fontWeight: 'normal' }}>continue shopping</h3></Link>
                        </div>
                    </td>
                </tr> :
                groups && Object.keys(groups).map((g, i) => {
                    var itms = groups[g]!
                    // const prnt = groups[g]!.find(x => x.BrandParentID === null);
                    // const pckgs = groups[g]!.filter(x => x.BrandParentID !== null);
                    //return <CartItem key={prnt?.ID} parent={prnt!} packages={pckgs!} />
                    return <CartItem key={g} products={itms} />
                })

            }
        </tbody>
    </table>

}

export default CartProducts
