//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverUrl } from '../tools';

const CountryPick = ({ onSelectValue, onSelectIso, value }) => {
    const [countryList, setCountryList] = useState([]);
    useEffect(() => {
        getResults();
    }, []);

    const getResults = async () => {
        const response = await axios.get(serverUrl('GetCountries'));
        const countriesList = response.data.map(x => { return { key: x.ID, lable: x.Name, iso: x.IsoCode } })
        setCountryList(countriesList)
        // const lst = [
        //     { key: 0, lable: 'address1' },
        //     { key: 1, lable: 'address1' },
        //     { key: 2, lable: 'address' },
        // ]
    }

    const handleChange = (e) => {
        const sel = e.target;
        const iso = sel.options[sel.selectedIndex].getAttribute('data-iso');
        onSelectIso(iso)
        onSelectValue(sel.value);
    }

    return (
        <select onChange={e => handleChange(e)} value={value}>
            <option data-iso={null} key={null}>{'Choose...'}</option>
            {countryList.map((x, i) => <option data-iso={x.iso} key={x.key} value={x.iso}>{x.lable}</option>)}
        </select>
    )
}

export default CountryPick
