//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import '../../style/confirm-set-password.css';

const ConfirmSetPassword = ({ clearForm }) => {
    return (

        <div className="clsConfirmSetPass">
            <h1>סיסמתך נשלחה</h1>
            <p className="clsText">סיסמתך נשלחה בהצלחה לכתובת הדוא"ל</p>
            <a onClick={clearForm}>להתחברות {'>'}</a>
        </div>
    )
}

export default ConfirmSetPassword;
