//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';

const BottomFooter = () => {
    return <div className="footer">
        <div className="clsPageAlign"  >
            כל הזכויות שמורות לJudaica Software
            <span>Created By</span><a href="http://www.win-site.co.il/" target="_blank" rel='noopener noreferrer'>WINSITE&nbsp;</a>
        </div>
    </div>

}

export default BottomFooter;