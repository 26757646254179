//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';


const dePascalCase = (text) => {
    // insert a space before all caps
    return text.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, str => str.toUpperCase());
}

const ScrollToTop = () => {
    let { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `Judaica Software - ${dePascalCase(pathname.split('/')[1])}`;
    }, [pathname]);
    return null;
}

export default ScrollToTop
