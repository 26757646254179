//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

export const currencyFormat = (input?: number) => {
    if (input === undefined) return ""
    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, }).format(input)
    return formatted;
};

export const serverBaseUrl = () => {
    if (process.env.NODE_ENV !== 'production') {
        return process.env.REACT_APP_SERVER_ROOT_DEV;
    }
    return process.env.REACT_APP_SERVER_ROOT;

}

export const serverUrl = (url: string) => {
    if (process.env.NODE_ENV !== 'production') {
        return `${process.env.REACT_APP_HOME_ROOT_DEV}${url}`;
    }
    return `${process.env.REACT_APP_HOME_ROOT}${url}`;

}

export const serverUrlGlobal = (url: string) => {
    if (process.env.NODE_ENV !== 'production') {
        return `${process.env.REACT_APP_GLOBAL_ROOT_DEV}${url}`;
    }
    return `${process.env.REACT_APP_GLOBAL_ROOT}${url}`;

}

export const actions = {
    //SET_TOTALS: 'SET_TOTALS',
    SET_PRODUCTS: 'SET_PRODUCTS',
    EDIT_PRODUCTS: 'EDIT_PRODUCTS',
    DELETE_PRODUCT_GROUP: 'DELETE_PRODUCT_GROUP',
    ADD_SHIPPING: 'ADD_SHIPPING',
    RESET: 'RESET',
}

