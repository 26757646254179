//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import '../style/support.css'
import CollapseAccordion from './CollapseAccordion';

const Support = () => {
    return (
        <section className="clsInsidePage">
            <div className="clsPageAlign">
                <div className="clsSupport">
                    <article className="clsText">
                        <h1>תמיכה</h1>
                        <h2>
                            התוכנות מתעדכנות כל הזמן ומוציאות גרסאות חדשות. <br />
                            כאן תוכלו לראות מה חדש בכל גרסה.
                        </h2>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר </p>
                        <span>בלה בלה בלה בלה בלה בלהבלהבלהבלהבלהבלהבלה  בלה בלה בלה </span>
                        <h2>
                            התוכנות מתעדכנות כל
                        </h2>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר </p>
                        <CollapseAccordion />
                    </article>
                </div>
            </div>
        </section>);
}

export default Support;