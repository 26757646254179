//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState } from 'react';
import axios from 'axios';
import { serverUrl } from '../../tools';
import '../../style/set-password.css';

const SetPassword = ({ email, showSuccess, setEmail }) => {
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSending(true);
        const reuslt = await axios.post(serverUrl('SetPassword'), { Email: email })
        setError(reuslt.data);
        if (reuslt.data === "") {
            showSuccess();
        }
        setSending(false);
    }
    
    return (

        <form className="clsSetPass" onSubmit={(e) => handleSubmit(e)}>
            <h1>שכחתי סיסמא</h1>
            <p className="clsText">אנא הזינו את כתובת המייל איתה נרשמתם ואנו נשלח את הסיסמא שלכם אליה במייל</p>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input disabled={sending} type="submit"   value={'\uf060'} />
            {sending && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '-12px', marginRight: '15px', color: '#4c1518' ,fontSize:'25px' }} />}
            <p style={{ color: 'red', fontSize: '20px', fontWeight: '400 !important', lineHeight: '23px', paddingTop: '10px' }}>{error}</p>
        </form>

    );
}

export default SetPassword;