//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react'

const GiftDetails = () => {
    return (
        <form className="clsBox">
            <div className="clsImg"><img src="images/present_icon.png" alt="" /></div>
            <h3>
                במידה והתוכנה שרכשת היא מתנה, אנא מלא את פרטי מקבל המתנה על מנת שנוכל לתת לו תמיכה ולשלוח לו עדכונים לתוכנה שנרכשה.
        </h3>
            <div className="clsHeadingLine">
                <h4>שם המוצר</h4>
                <h4>שם מקבל המתנה</h4>
                <h4>דוא"ל</h4>
                <h4>טלפון</h4>
            </div>
            <div className="clsSubLine">
                <span>אוצר החכמה 13.0</span>
                <input type="text" name="name" placeholder="שם מקבל המתנה" />
                <input type="text" name="name" placeholder="מייל" />
                <input type="text" name="name" placeholder="טלפון" />
                <span><i className="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <div className="clsSubLine">
                <span>אוצר החכמה 13.0</span>
                <input type="text" name="name" placeholder="שם מקבל המתנה" />
                <input type="text" name="name" placeholder="מייל" />
                <input type="text" name="name" placeholder="טלפון" />
                <span><i className="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <p>*המידע שתספק לנו לא ישמש עבור פרסומות או ספאם, אלא לתועלת מקבל המתנה בלבד.</p>
            <input type="submit" value="אישור" />
        </form>
    )
}

export default GiftDetails
