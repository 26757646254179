//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import * as m from './Declarations';

function serverBaseUrl() {
    if (process.env.NODE_ENV !== 'production') {
        return process.env.REACT_APP_SERVER_ROOT_DEV;
    }
    return process.env.REACT_APP_SERVER_ROOT;
}

export function getActionUrl(controller: string, action: string, id?: number, search?: string): string {
    if (!controller) { return ''; }
    let qry = search || '';
    if (qry !== '') qry = '?' + qry;
    const ret = serverBaseUrl() + '/' + [controller, action, id].filter(x => x).join('/') + qry;
    return ret;
};

export function performAction(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    if (usePost) {
        return m.fetchPlus(getActionUrl(controller, action, undefined), {
            method: "POST",
            body: JSON.stringify(vals),
            headers: {
                'Content-Type': 'application/json'
            },
            signal: signal,
            credentials: 'include'
        });
    } else {
        return m.fetchPlus(getActionUrl(controller, action, undefined, m.queryVals(vals)), { credentials: 'include' });
    }
}

export function dePascalCase(input: string) {
    const result = input.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export function performActionT<T>(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    var credtyp: RequestCredentials = "include"
    if (usePost) {
        if (Object.values(vals).some(x => x instanceof File)) {
            const formData = new FormData();
            for (const key in vals) {
                formData.append(key, vals[key]);
            }
            return m.fetchJson<T>(getActionUrl(controller, action, undefined), {
                method: "POST",
                body: formData,
                credentials: credtyp,
                signal
            });
        }
        else {
            const url = getActionUrl(controller, action, undefined)
            const jvals = JSON.stringify(vals);
            const ret = m.fetchJson<T>(url, {
                method: "POST",
                body: jvals,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: credtyp,
                signal
            });
            return ret
        }

    } else {
        return m.fetchJson<T>(getActionUrl(controller, action, undefined, m.queryVals(vals)), { credentials: credtyp });
    }
}

var compare = <T>(x: T, y: T) => {
    if (x === y) { return 0; }
    if (x < y) { return -1; }
    //if (x > y)
    return 1;
};


declare global {
    interface Array<T> {
        sortBy: (generator: (value: T) => any[] | any) => T[];
    }
}

Array.prototype.sortBy = function <T>(this: Array<T>, sortkeysGenerator: (x: T) => any[] | any): Array<T> {
    this.sort((a, b) => {
        var sortkeysA = sortkeysGenerator(a);
        var sortkeysB = sortkeysGenerator(b);

        if (!(sortkeysA instanceof Array)) { return compare(sortkeysA, sortkeysB); }

        var length = sortkeysA.length;
        for (var i = 0; i < length; i++) {
            var compareResult = compare(sortkeysA[i], sortkeysB[i]);
            if (compareResult !== 0) { return compareResult; }
        }
        return 0;
    });
    return this;
};