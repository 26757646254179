//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import axios from 'axios';
//import { serverUrl } from '../tools';
import FooterLogo from '../images/logo_footer.svg';
import { LocalClient, MiniBrand } from '../Declarations';
//import { sortBy } from '../general';

const Footer = () => {
    const [brands, setBrands] = useState<MiniBrand[]>();

    useEffect(() => {
        const getBrands = async () => {
            const result = await LocalClient.Public.GetMainBrands()// await axios.get(serverUrl('GetMainBrands'))
            setBrands(result);
        }
        getBrands();
    }, []);

    return <footer>
        <div className='clsPageAlign' style={{ width: 1170, margin: "0 auto" }}>
            <Link to='/'><img src={FooterLogo} alt='Judaica Software' /></Link>
            <p>פסקה קצרה אודות החברה איפסוםדולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופט נענע ערקה  דיאם. וסטיב כערולום אט דולור. </p>
            <div>
                <Link to='/brands'>החנות</Link>
                <Link to='/support'>תמיכה</Link>
                <Link to='/news'>עדכונים</Link>
                <Link to='/contact'>צור קשר</Link>
            </div>
            <div>
                <Link to={'/brands'}>מוצרים</Link>
                <ul>
                    {brands && brands.slice(0, 6).sortBy(x => x.Priority).map(x => <li key={x.ID}><Link to={`/brand/${x.ID}`}>{x.Name}</Link></li>)}
                </ul>
            </div>
        </div>
    </footer>

}

export default Footer;