//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, useContext, FC } from 'react';
import { Skeleton } from 'antd'
//import '../../style/products.css';
import '../style/products.css';
import { useLocation } from "react-router-dom";
//import { LocalClient, MiniBrand } from '../../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';
import BrandBox from '../misc';
import { MiniBrand, LocalClient } from '../Declarations';

const Brands: FC = props => {

    const query = new URLSearchParams(useLocation().search)
    const categoryQuery = query.get("categoryId");

    const [brands, setBrands] = useState<MiniBrand[]>();
    const [categories, setCategories] = useState<{ Key: number, Value: string }[]>();
    const [selectedCategory, setSelectedCategory] = useState(+categoryQuery!); // the + is to convert to integer
    const [isBrandsloading, setIsBrandsloading] = useState(false);
    const [isCategoriesLoading, setisCategoriesLoading] = useState(false);
    const lcl = useContext(LocaleContext)!

    useEffect(() => {
        const getCategories = async () => {
            setisCategoriesLoading(true);
            const cats = await LocalClient.Public.Categories()
            cats.unshift({ Key: 0, Value: lcl.localized("AllProducts") })
            setCategories(cats);
            setisCategoriesLoading(false);
        }
        getCategories();
    }, [])

    useEffect(() => {
        getbrands(selectedCategory);
    }, [selectedCategory]);

    const getbrands = async (categoryId: number) => {
        setIsBrandsloading(true);
        const result = await LocalClient.Public.Brands(categoryId)
        setBrands(result)
        setIsBrandsloading(false)
    };

    const selectedCategoryIndex = categories?.findIndex(x => x.Key === selectedCategory)
    var curcat = categories?.find(x => x.Key === selectedCategory)

    //const skeletonBrands = () => [...Array(6).keys()].map(x => <BrandBox key={x} />);
    const skeletonCategories = () => [...Array(4).keys()].map(x => <li key={x}><Skeleton title={true} paragraph={false} active /></li>);

    return <div className="clsProductsSection clsInsidePage">
        <div className="clsPageAlign" style={{   display: 'flex' }}>
            <nav style={{ whiteSpace: 'nowrap' }}>
                <ul>
                    {isCategoriesLoading ? skeletonCategories() : categories?.map((c, i) => {
                        return <li key={c.Key}>
                            <span className={selectedCategory === c.Key ? 'clsSelected' : ""} onClick={e => setSelectedCategory(c.Key)}>{c.Value}</span>
                        </li> 
                    })}
                </ul>
            </nav>

            <section className="clsProducts">
                <h1>{curcat?.Value}</h1>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {brands?.map((x, i) => <BrandBox key={x.ID} {...x} />)}
                </div>
            </section>

        </div>

    </div>
}

export default Brands;