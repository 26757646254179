//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';



// this should take headers and contents (or as single object..) as props for reusability...
const CollapseAccordion = ({title}) => {


    const { Panel } = Collapse;

    const borderStyle = { borderBottom: 'solid 1px #ede6de' };
    const iconStyle = {
        color: '#4c1518',
        fontSize: '18px'
    }


    const header = (text) => {
        return <h3 className='clsQuestionHeader' >{text}</h3>
    }




    return (
        <section className="clsQuestionsSide">
            <h2>{title}</h2>
            <Collapse
                expandIcon={({ isActive }) => isActive ? <MinusOutlined style={iconStyle} /> : <PlusOutlined style={iconStyle} />}
                // defaultActiveKey={['1']}
                ghost accordion>
                <Panel header={header("מה היתרונות המיוחדים ברכישה בJudaica Software?")} key="1" style={borderStyle}>
                    <div className="clsText clsAccordionContent" >
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("האם ניתן להחזיר תוכנה לאחר הקניה?")} key="2" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("כמה ספרים יש במאגר?")} key="3" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("כמה זמן לוקח למשלוח להגיע אליי?")} key="4" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("מה היתרונות המיוחדים ברכישה בJudaica Software?")} key="5" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("האם ניתן להחזיר תוכנה לאחר הקניה?")} key="6" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
                <Panel header={header("כמה ספרים יש במאגר?")} key="7" style={borderStyle}>
                    <div className="clsText clsAccordionContent">
                        לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור, קראס אגת לעקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
                    </div>
                </Panel>
            </Collapse>
        </section>);
}

export default CollapseAccordion;