//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useContext } from 'react';
import '../style/connect.css';
//import SetPassword from './SetPassword';
//import ConfirmSetPassword from './ConfirmSetPassword';
//import { UserContext } from '../../Contexts/UserContext';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { LocaleContext } from '../Contexts/LocaleContext';
import ConfirmSetPassword from './Login/ConfirmSetPassword';
import SetPassword from './Login/SetPassword';


const Login = () => {
    const app = useContext(LocaleContext)!
    //const { localized } = useContext(LocaleContext)
    const returnUrl = new URLSearchParams(useLocation().search).get("return")
    const history = useHistory();
    //const action = useParams();
    let prms = useParams<{ action: string }>();

    //const { logIn, errorMessage } = useContext(UserContext)

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    //const [getNewPassword, setGetNewPassword] = useState(action ? true : false);
    const [getNewPassword, setGetNewPassword] = useState(prms.action ? true : false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isSumbmitting, setIsSumbmitting] = useState(false);

    const submit = async () => {
        setIsSumbmitting(true);
        const success = await app.login(userName, password);
        setIsSumbmitting(false);
        if (success) {
            if (returnUrl) {
                //navigate to the right loaction 
                history.push(`/${returnUrl}`);
            }
            else {
                // navigate home
                history.push('/');
            }
        }
    }

    const clearForm = () => {
        setUserName('');
        setPassword('');
        setShowSuccess(false);
        setGetNewPassword(false);
    }
    const setShowSuccessTrue = ()=>{
        setShowSuccess(true);
    }
    // pattern='^[a-z0-9א-ת\.\?\+\/_&-=]+@[a-z0-9.-]+\.[a-z0-9]{2,}'
    return (
        <section className="clsProductSaction clsInsidePage" >
            <div className="clsPageAlign">
                {showSuccess ? <ConfirmSetPassword clearForm={clearForm} /> :
                    getNewPassword ?
                        <SetPassword email={userName} setEmail={(x: string) => setUserName(x)} showSuccess={setShowSuccessTrue} /> :
                        <form className="clsConnect">
                            <h1>{app.localized('Login')}</h1>
                            <div className="clsBox">
                                <div>
                                    <h3>שם משתמש (דואר אלקטרוני)</h3>
                                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                </div>
                                <div>
                                    <h3>סיסמא</h3>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {/* <Input.Password placeholder="input password" /> */}
                                </div>
                                {app.errorMessage && <span style={{ color: 'red', fontSize: '19px', display: 'block' }}>{app.errorMessage}</span>}
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-end" }} >
                                    <div style={{ display: "flex", alignItems: "baseline" }}  >
                                        <input type="button" value='כניסה' onClick={submit} />
                                        {isSumbmitting && <i className="fa fa-spinner fa-pulse" style={{ color: '#4c1518', fontSize: '25px', padding: '20px' }} />}
                                    </div>
                                    <div className="clsInput">
                                        {/* <input type="checkbox" name="one" id="Checkbox1" />
                                        <label htmlFor="Checkbox1">זכור אותי</label><hr /> */}
                                        <a onClick={() => setGetNewPassword(true)}>שכחתי סיסמא</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                }
            </div>
        </section>








    );
}

export default Login;