//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useContext, useEffect, FC } from 'react';
import { currencyFormat } from '../tools';
import { NavLink, Link } from "react-router-dom";
//import { SaleContext } from '../Contexts/SaleContext';
import Logo from '../images/headerLogo.png';
import * as m from '../Declarations';
import { LocaleContext } from '../Contexts/LocaleContext';

const Header: FC = props => {
    //const { state } = useContext(SaleContext)!;
    const [categories, setCategories] = useState<{ Key: number, Value: string }[]>();
    const lcl = useContext(LocaleContext)

    useEffect(() => {
        const getCategories = async () => {
            const result = await m.LocalClient.Public.Categories();//   Axios.get(serverUrl('categories'));
            setCategories(result);
        };
        getCategories();
    }, []);

    return <div className="clsHeaderSection" >
        <div className="clsPageAlign">
            <header style={{ display: 'flex', width: "100%" }}>
                <Link to="/"><img src={Logo} alt="Judaica Software" /></Link>
                <nav className="clsMainMenu" style={{ flexGrow: 1 }}>
                    <ul style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <li> <NavLink to="/about" activeClassName='clsActive'>{lcl?.localized('About')}</NavLink></li>
                        <li>
                            <NavLink to="/brands" activeClassName='clsActive'>{lcl?.localized('Store')} {categories && <span><i className="fa fa-angle-down" aria-hidden="true"></i></span>}</NavLink>
                            {categories && <ul>
                                {categories.map(c => {
                                    const url = `/Brands?categoryId=${c.Key}`
                                    return <li key={c.Key}><Link to={url}>{c.Value}</Link></li>
                                })}
                            </ul>}
                        </li>
                        <li><NavLink to="/support" activeClassName='clsActive'>{lcl?.localized('Support')}</NavLink></li>
                        <li><NavLink to="/news" activeClassName='clsActive'>{lcl?.localized('WhatsNew')}</NavLink></li>
                        <li><NavLink to="/contact" activeClassName='clsActive'>{lcl?.localized('ContactUs')}</NavLink></li>
                    </ul>
                </nav>
                <div style={{ display: 'flex', alignItems: 'center' }}> <div className="clsClientArea" style={{ padding: 10, display: 'flex', gap: 15, fontWeight: 900, color: "#4c1518" }}>
                    <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                    <Link to="/shoppingCart">{(lcl?.data?.Sale?.ProductCount || 0) + ' ' + lcl?.localized('Items')}</Link>
                    <span>{currencyFormat(lcl?.data?.Sale?.GrandTotal || 0)}</span>
                    <span className="clsLine">|</span>
                    <Link to="/purchase">{lcl?.localized('Checkout')}</Link>
                </div></div>


            </header>
        </div>
        {props.children}
    </div>

}

export default Header;