//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState } from 'react';
//import { AutoComplete, Select } from 'antd';
import * as m from '../Declarations';
import {LocaleContext} from '../Contexts/LocaleContext'
interface KeyValueArr{
    arr:{Key:string,Value:string}[];
}
interface LookupProps{
    onSelect:(detailes:any) => void; 
}
const AddressLookup = (props:LookupProps ) => {
    const app = useContext(LocaleContext)
    const [addressList, setAddressList] = useState<{Key:string,Value:string}[]>([]);
    const [value,setValue]=useState('')

    const getResults = async(text:string) => {
        setValue(text);
        var res= await m.MachshevetClient.Global.FindPlaces(text);
        setAddressList(res);
    }
    const handleSelect = async (address:{Key:string,Value:string}) => {
        setAddressList([])
        setValue(address.Value)
        const fullDetails = await m.MachshevetClient.Global.GetPlaceDetails(address.Key);
        props.onSelect(fullDetails)
    }

    return (
        <><input value={value} onChange={e => getResults(e.currentTarget.value)} placeholder={app?.localized("Search Address")} style={{ width: '100%' }} />
            <div style={{marginTop:-24}}>
                {addressList.map(addr =>
                    <option className='lookupOption' key={addr.Key} 
                        onClick={e => handleSelect(addr)}
                        style={{ cursor: 'pointer', background:'antiquewhite', margin: '2px' }}>{addr.Value}</option>)}
            </div>
        </>

        // <AutoComplete
        //     value={value}
        //    style={{width:'100%'}}
        //    //onSelect={e => handleSelect(e)}
        //    onSearch={getResults}
        //    placeholder="Search Address">
        //         {addressList.map(addr => <AutoComplete.Option className='lookupOption'key={addr.Key} value={addr.Key + '   ' + addr.Value}>{addr.Value}</AutoComplete.Option>)}
        // </AutoComplete>
    )
}

export default AddressLookup
