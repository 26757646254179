//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import '../style/about.css';
import CollapseAccordion from './CollapseAccordion';
import image from '../images/img.png'


const About = () => {
    return (
        <section className='clsProductsSaction clsInsidePage'>
            <div className="clsPageAlign ">
                <div className="clsAbout">
                    <article className="clsText">
                        <h1>אודות</h1>
                        <p>Judaica Software קמה בשנת 2002 ושמה לעצמה מטרה להנגיש ולייבא את כל תוכנות היודאיקה לארה"ב.  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר טוק.</p>
                        <h2>כל הסיבות לרכוש דוקא ב Judaica Software</h2>
                        <h3>1. קונים בחו”ל בקלות</h3>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור. הועניב היושבב שערש שמחוילברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.</p>
                        <h3>2. הגרסאות המעודכנות ביותר</h3>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור. הועניב היושבב שערש שמחוילברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.</p>
                        <h3>3. תמיכה טכנית אישית</h3>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דחלקוליס וולופטה דיאם. וסטיב כערולום אט דולור. הועניב היושבב שערש שמחוילברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.</p>
                        <img src={image} alt="infoImage" />
                        <h2>אצלנו, הלקוח תמיד מס' 1!</h2>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר טוק.</p>
                        <CollapseAccordion title='שאלות נפוצות!' />
                    </article>
                </div>
            </div>
        </section>);
}

export default About;