//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { currencyFormat } from '../tools';
import { LocalClient, MiniProduct } from '../Declarations';
import { BrandImage } from '../misc';
import EditCartPopUp from './EditCartPopUp';
import { LocaleContext } from '../Contexts/LocaleContext';

const CartItem: FC<{ products: MiniProduct[] }> = props => {
    const app = useContext(LocaleContext)!;
    const [quantity, setQuantity] = useState(1);
    const [editDiskGroup, setEditDiskGroup] = useState(0);


    const prnt = props.products.find(x => x.BrandParentID === null);
    const pckgs = props.products.filter(x => x.BrandParentID !== null);

    const price = (pckgs.length ? pckgs.map(x => x.Total).reduce((a, c) => a + c) : 0) + (prnt?.Total || 0);

    const editGroup = (group: number) => {
        setEditDiskGroup(group);
    }

    return prnt ? <tr className="row">
        <td className="actions" >
            <div>
                {/* <span onClick={() => dispatch({ type: actions.DELETE_PRODUCT_GROUP, payLoad: props.parent.DiskGroup })}> */}
                <span onClick={async e => {
                    var sl = await LocalClient.Public.RemoveProduct(prnt?.DiskGroup!)
                    app.setSale(sl)

                }}>
                    <i className="fa fa-times-circle" aria-hidden="true" ></i>
                </span>
            </div>
            <div>
                <span onClick={() => editGroup(prnt?.DiskGroup!)}>
                    <i className="fa fa-pencil-square" aria-hidden="true" ></i>
                </span>
            </div>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
            <BrandImage id={prnt!.BrandID} name={prnt?.BrandName!} style={{ marginLeft: 30, height: 60 }} /></td>
        <td className="productName">
            <Link to={`/brand/${prnt?.BrandID}`}><h4>{prnt?.Name}</h4></Link>
            <div>
                {pckgs.map(p => <p key={p.ID}>{p.Name}</p>)}
            </div>

        </td>
        <td className="price">{currencyFormat(price)}</td>
        <td className="clsTotalAmount">
            <div onClick={() => setQuantity(quantity < 10 ? quantity + 1 : 10)}>+</div>
            <div>{quantity}</div>
            <div onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</div>
        </td>
        <td className="total">{currencyFormat(price * quantity)}</td>
        {editDiskGroup && <EditCartPopUp diskGroup={editDiskGroup} onClose={() => setEditDiskGroup(0)} />}
    </tr> : <tr />



}


export default CartItem
