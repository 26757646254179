//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useEffect, useState } from 'react'
import { ConfigProvider, Modal } from 'antd';
import AddressLookup from '../components/AddressLookup';
import CountryPick from '../components/CountryPick';
import { AddressData} from '../Declarations';

export interface AddressPopupProps{
  handleChange:(address:AddressData)=>void,
  address:AddressData,
  onSave:()=>void, 
  handleClose:()=>void, 
  isShipping:Boolean 
}

const NewAddressPopUp = (props:AddressPopupProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true); 
  const [address,setAddress]=useState(props.address);

  const onGoogleLookupSelect = (data:AddressData) => {
    setAddress(data)
  };
  useEffect(()=>{
    props.handleChange(address)
  },[address]);

  const change=(field:string,value:any)=>{
    setAddress(prev=> ({...prev,[field]: value}));
  }
  const handleSave = async () => {
    const RequierdFields = ['CityName', 'Zip', 'Street', 'House', 'StateName', 'CountryName', 'CountryIso'];
    for (const [key, value] of Object.entries(address)) {
      const match = RequierdFields.find(x => x === key);
      if (match && !value) {
        setError(`${key} is missing`);
        return;
      }
    }
    setLoading(true);
    await props.onSave();
    setVisible(false);
    setLoading(false);
    props.handleClose();
  }

  return (
    <ConfigProvider direction={document.dir as 'ltr'|'rtl'}>
      <Modal
        visible={visible}
        title="New Address"
        onOk={handleSave}
        onCancel={props.handleClose}
        width={'fit-content'}
        maskClosable={false}
        confirmLoading={loading}
      >
        <div className="clsAddressForm" style={{ display: 'grid' }}>
          <div className="clsFild largeInput">
            <span>google lookup *</span>
            <AddressLookup  onSelect={onGoogleLookupSelect} />
          </div>
          <div>
            <div className="clsFild regularInput">
              <span>כתובת *</span>
              <input type="text" placeholder="רחוב" value={address?.Street} onChange={e => change('Street', e.target.value)} />
            </div>
            <div className="clsFild regularInput clsNoneMargin clsMobileDisplayNone">
              <span>&nbsp;</span>
              <input type="text" placeholder="מספר בית" value={address?.House || ''} onChange={e =>{change('House', +e.target.value)}} />
            </div>
          </div>
          <div>
            <div className="clsFild regularInput">
              <input type="text" placeholder="מספר דירה" value={address?.Apt} onChange={e => {change('Apt', +e.target.value)}} />
            </div>
            <div className="clsFild smallInput">
              <input type="text" placeholder="קומה (אופציונלי)" value={address?.Floor} onChange={e =>{change('Floor', +e.target.value)}} />
            </div>
            <div className="clsFild smallInput clsNoneMargin">
              <input type="text" placeholder="כניסה (אופציונלי)" value={address?.Entrance} onChange={e =>{change('Entrance', +e.target.value)}} />
            </div>
          </div>
          <div>
            <div className="clsFild regularInput">
              <span>עיר *</span>
              <input type="text" placeholder="" value={address?.CityName} onChange={e => {change('CityName', e.target.value)}} />
            </div>
            <div className="clsFild regularInput clsNoneMargin">
              <span>מדינה *</span>
              <input type="text" placeholder="" value={address?.StateName} onChange={e =>{change('StateName', e.target.value)}} />
            </div>
          </div>
          <div>
            <div className="clsFild regularInput">
              <span>ארץ *</span>
              <CountryPick 
              value={address?.CountryIso} 
              onSelectIso={(val => {change('CountryIso',val)})} 
              onSelectValue={val =>{change('CountryName', val)}} 
              />
            </div>
            <div className="clsFild regularInput clsNoneMargin">
              <span>מיקוד *</span>
              <input type="text" placeholder="" value={address?.Zip} onChange={e => {change('Zip', e.target.value)}} />
            </div>
          </div>
          {props.isShipping &&
            <div className="clsFild largeInput">
              <span>לכבוד *</span>
              <input type="text" placeholder="" value={address?.Attention} onChange={e =>{change('Attention', e.target.value)}} />
            </div>}
          {error && <div className="clsFild regularInput">
            <span style={{ color: "red" }}>{error}</span>
          </div>}
        </div>
      </Modal>
    </ConfigProvider>
  )
}

export default NewAddressPopUp
