//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState } from 'react'
//import { SaleContext } from '../Contexts/SaleContext';
import '../style/payment.css';
import { actions, currencyFormat, serverUrl } from '../tools'
import axios from 'axios';
import Confirm from './Pay/Confirm';
import { LocaleContext } from '../Contexts/LocaleContext';


const Pay = () => {
    //const { state, dispatch } = useContext(SaleContext)
    const [cardNumber, setCardNumber] = useState('');
    const [expireMonth, setExpireMonth] = useState(1);
    const [expireYear, setExpireYear] = useState(new Date().getFullYear());
    const [cvv, setCvv] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [error, setError] = useState('');
    const [orderNumber, setOrderNumber] = useState(null);
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setloading] = useState(false);
    const app = useContext(LocaleContext)


    const validateCard = async () => {
        const response = await axios.get(serverUrl('IsCardValid'), { params: { cardNumber: cardNumber } })
        if (response.data === 'False') {
            setError('invalid credit card number')
        } else {
            setError('')
        }
        return response.data;
    }

    const submitPayment = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        setloading(true);
        const isValid = await validateCard();
        if (isValid === 'False') {
            setloading(false);
            return
        };
        if (cvv === '' || cvv.length < 3) {
            setError('Invlid security code (CVV)')
            setloading(false);
            return
        }
        const response = await axios.post(serverUrl('Pay'), {
            CardNumCryp: cardNumber,
            CardVVCodeCryp: cvv,
            ExpiryMonth: expireMonth,
            ExpiryYear: expireYear
        }, { withCredentials: true });

        if (response.data.Success) {
            setOrderNumber(response.data.SaleID);
            setConfirmed(true);
            setError('');
            //dispatch({ type: actions.RESET, payLoad: undefined });
        } else {
            setError(response.data.Error)
        }
        setloading(false);
    }

    const getYears = () => {
        const thisYear = new Date().getFullYear();
        let array = [];
        for (let index = thisYear; index < thisYear + 10; index++) {
            array.push(index);
        }
        return array;
    }
    return (
        <section className="clsProductsSaction clsInsidePage">
            <div className="clsPageAlign">
                {confirmed ? <Confirm saleId={orderNumber} /> :
                    <div className="clsPayment">
                        <h1>תשלום</h1>
                        <div className="clsBox">
                            <h2>פרטי תשלום</h2>
                            <hr />
                            <h3>סה"כ מוצרים:</h3> {currencyFormat(app?.data?.Sale?.ProductTotal)}
                            <hr />
                            <h3>משלוח:</h3> {currencyFormat(app?.data?.Sale?.ShipCharge)}
                            <hr />
                            <h3>סה"כ לתשלום:</h3> {currencyFormat(app?.data?.Sale?.GrandTotal)}
                        </div>
                        <form className="clsBox">
                            <h2>אמצעי תשלום</h2>
                            <div>
                                <h3>מספר כרטיס:</h3>
                                <input
                                    type="text"
                                    value={cardNumber}
                                    onChange={e => setCardNumber(e.target.value)}
                                    onBlur={validateCard} />
                            </div>
                            <div>
                                <h3>תאריך תפוגה:</h3>
                                <select name="year"
                                    value={expireYear}
                                    onChange={e => setExpireYear(+e.target.value)}>
                                    {getYears().map(x => <option key={x} value={x}>{x}</option>)}
                                </select>

                                <select name="month"
                                    value={expireMonth}
                                    onChange={e => setExpireMonth(+e.target.value)}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => <option key={x} value={x} >{x}</option>)}
                                </select>
                            </div>
                            <div>
                                <h3>ספרות אבטחה: (?)</h3>
                                <input type="text" name="name" value={cvv} onChange={e => setCvv(e.target.value)} maxLength={4} />
                            </div>
                            <div>
                                <h3>שם בעל הכרטיס:</h3>
                                <input type="text" name="name" value={cardHolderName} onChange={e => setCardHolderName(e.target.value)} />
                            </div>
                            {/* <div>
                            <h3>תשלומים:</h3>
                            <select name="pays_num">
                                <option value="1" selected>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div> */}
                            {error && <h2 style={{ textAlign: 'center', color: 'red' }}>{error}</h2>}
                            <button onClick={e => submitPayment(e)}
                                disabled={loading}>
                                תשלום
                                {loading && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '-12px', marginRight: '15px', color: 'white' }} />}
                            </button>
                            {/* <input type="submit" value="תשלום" /> */}
                        </form>

                    </div>
                } </div>

        </section>
    )
}

export default Pay
