//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useContext, useEffect } from 'react';
import { Steps, Button, ConfigProvider } from 'antd';
import * as m from '../Declarations';
import PersonalInfo from './PersonalInfo';
import AddressContainer from './AddressContainer';
import ShippingRates from './Purchase/ShippingRates';
import { actions, serverUrl } from '../tools';
//import { SaleContext } from '../Contexts/SaleContext';
import { LocaleContext } from '../Contexts/LocaleContext';


const { Step } = Steps;
interface PurchaseStepsProps {
    HandleSubmit: (id: number) => void
}
const PurchaseSteps = (props: PurchaseStepsProps) => {
    //const { dispatch } = useContext(SaleContext)
    const app = useContext(LocaleContext)
    const [currentStep, setCurrentStep] = useState(0);
    const [warning, setWarning] = useState('');
    const [error, setError] = useState('');
    const [personDetails, setPersonDetails] = useState(app?.data?.User);
    const [billingAddressId, setBillingAddressId] = useState(0);
    const [shippingAddressId, setShippingAddressId] = useState(0);
    const [billingAndShippingSame, setBillingAndShippingSame] = useState(false);
    const [shipTypeId, setShipTypeId] = useState<number>();
    const [saleComment, setSaleComment] = useState('');


    const next = () => { setCurrentStep((currentStep) => currentStep + 1) }
    const prev = () => { setCurrentStep((currentStep) => currentStep - 1) }

    //#region PERSON DETAILS
    const onDetailsChange = (fieldName: string, value: string) => {
        setPersonDetails(prev => ({
            ...prev!,
            [fieldName]: value
        }))
    }

    useEffect(() => {
        setPersonDetails(app?.data?.User)
    }, [app?.data?.User]);

    const submitPersonDetails = async () => {
        setError('');
        const data = await m.LocalClient.Public.PersonDetails(personDetails!)
        //     FirstName: personDetails.firstName,
        //     LastName: personDetails.lastName,
        //     Email: personDetails.email,
        //     Company: personDetails.companyName,
        //     Phone: personDetails.phone,
        //     Cell: personDetails.cell,
        // }, { withCredentials: true })
        // if (data.errorText) {
        //     setError(data.errorText);
        // } else {
        next();
        //}
    }
    //#endregion 

    //#region ADDRESSES DETAILS
    const handleAddressChange = (id: number, isShipping: boolean) => {
        if (!isShipping) {
            setBillingAddressId(id);
        } else {
            setShippingAddressId(id);
        }
    }

    const submitBillingAddress = () => {
        m.LocalClient.Public.SetAddress(billingAddressId, true, billingAndShippingSame);
        next();
        if (billingAndShippingSame) { next() };
    }
    const submitShippingAddress = () => {
        m.LocalClient.Public.SetAddress(shippingAddressId, false, billingAndShippingSame); // technicaly irelevant
        next();
    }
    //#endregion

    const submitShippingDetails = async () => {
        if (shipTypeId) {
            //const result = await m.LocalClient.Public.AddShipping(shipTypeId!, saleComment);
            await m.LocalClient.Public.AddShipping(shipTypeId!, saleComment);
            // setError('')
            // dispatch({ type: actions.SET_TOTALS, payLoad: undefined });
            //HandleSubmit()
            // TODO: refresh the totals
        } else {
            setError('please select a shipping type!')
        }

    }
    const steps = [
        {
            title: 'Personal Info',
            content: <PersonalInfo handleChange={onDetailsChange} person={personDetails!} />,
            onSubmit: submitPersonDetails,
        },
        {
            title: 'Biiling Address',
            content: <AddressContainer
                addressId={billingAddressId}
                setAddressId={handleAddressChange}
                billingAndShipping={billingAndShippingSame}
                setBillingAndShipping={setBillingAndShippingSame}
                isShipping={false} />,
            onSubmit: submitBillingAddress,

        },
        {
            title: 'Shipping Address',
            content: <AddressContainer
                isShipping={true}
                addressId={shippingAddressId}
                setAddressId={handleAddressChange} />,
            onSubmit: submitShippingAddress,

        },
        {
            title: 'Shipping',
            content: <ShippingRates shipTypeId={shipTypeId} setShipTypeId={setShipTypeId} comment={saleComment} setComment={setSaleComment} />,
            onSubmit: submitShippingDetails,

        },
    ];

    const stepsContent = {
        marginTop: '16px',
        border: '1px dashed #e9e9e9',
        borderRadius: '2px',
        backgroundColor: '#fafafa',
        minHeight: '200px',
        textAlign: 'center',
        paddingTop: '80px',
    }

    const stepsAction = {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'row-reverse'
    }

    return (
        <>
            <ConfigProvider direction={document.dir as 'ltr' | 'rtl'}>
                <Steps current={currentStep} style={{ marginBottom: '50px' }}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </ConfigProvider>
            <div className="steps-content" >{steps[currentStep].content}</div>

            {error && <div className="clsFild largeInput">
                <span style={{ color: 'red' }} >{error}</span>
            </div>}

            <div className="steps-action" style={{ marginTop: '24px', display: 'flex', flexDirection: 'row-reverse' }}>
                {currentStep < steps.length - 1 && (
                    <Button type="primary" onClick={steps[currentStep].onSubmit}>
                        Next
                    </Button>
                )}
                {currentStep === steps.length - 1 && (
                    <Button type="primary" onClick={steps[currentStep].onSubmit}>
                        Done
                    </Button>
                )}
                {currentStep > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </>
    )
}

export default PurchaseSteps

