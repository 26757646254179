//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useEffect, useState } from 'react';
//import '../../style/news.css'
import '../style/news.css'
import { Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { LocalClient, MiniStatement } from '../Declarations';
//import CollapseAccordion from '../CollapseAccordion';



const News = () => {
    const [state, setState] = useState<MiniStatement[]>();

    useEffect(() => {
        const getData = async () => {
            const result = await LocalClient.Public.News()
            setState(result);
        }
        getData();
    });

    const { Panel } = Collapse;

    const borderStyle = { borderBottom: 'solid 1px #ede6de' };
    const iconStyle = {
        color: '#4c1518',
        fontSize: '18px'
    }


    const header = (text: string | undefined) => {
        return <h3 className='clsQuestionHeader' >{text}</h3>
    }

    return (
        <section className="clsInsidePage">
            <div className="clsPageAlign">
                <div className="clsNews">
                    <article className="clsText">
                        <h1>מה חדש?</h1>
                        <h2>
                            התוכנות מתעדכנות כל הזמן ומוציאות גרסאות חדשות. <br />
                            כאן תוכלו לראות מה חדש בכל גרסה.
                        </h2>
                        <p>לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר </p>

                        <section className="clsQuestionsSide">

                            <Collapse ghost accordion expandIcon={({ isActive }) => isActive ? <MinusOutlined style={iconStyle} /> : <PlusOutlined style={iconStyle} />} >
                                {state?.map(x => <Panel header={header(x.Title)} key="1" style={borderStyle}>
                                    <div className="clsText clsAccordionContent" >                    {x.Description}                </div>
                                </Panel>)}
                            </Collapse>
                        </section>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default News;