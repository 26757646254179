//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
// css
import './style/general.css'
import './style/font-awesome.min.css';
// components
import TopBar from './components/TopBar';
import Header from './components/Header';
import Footer from './components/Footer';
import BottomFooter from './components/BottomFooter';
import Home from './components/Home/Home';
import Support from './components/Support';
import Contact from './components/Contact';
import Brand from './components/Brand/Brand';
import ShoppingCart from './components/ShoppingCart';
import Purchase from './components/Purchase';
import Pay from './components/Pay';
import ScrollToTop from './components/ScrollToTop';
import Policy from './components/Policy';
//import SaleContextProvider from './Contexts/SaleContext';
import LocaleContextProvider from './Contexts/LocaleContext';
import News from './components/News'
import Login from './components/Login';
import About from './components/About';
import Brands from './components/Brands';
function App() {
  return (
    <>
      <LocaleContextProvider>
        <BrowserRouter>
          <ScrollToTop />
          <TopBar />
          <div id="mainpage">
            <div style={{ width: 1170, margin: "0 auto" }}>
              <Header />
              <Switch>
                <Route path={'/about'} render={About} />
                <Route path={'/support'} component={Support} />
                <Route path={'/news'} component={News} />
                <Route path={'/contact'} component={Contact} />
                <Route path={'/brands'} render={({ location }) => {
                  const keyString = new URLSearchParams(location.search).get('categoryId');
                  return (<Brands key={keyString || 0} />)
                }} />
                <Route path={'/brand/:id'} component={Brand} />
                <Route path={'/connect/:action?'} component={Login} />
                <Route path={'/shoppingCart'} component={ShoppingCart} />
                <Route path={'/purchase'} component={Purchase} />
                <Route path={'/pay'} component={Pay} />
                <Route path={'/policy'} component={Policy} />
                <Route path={'/'} component={Home} />
              </Switch>
            </div>
          </div>
          <Footer />
        </BrowserRouter>
        <BottomFooter />
      </LocaleContextProvider>
    </>
  );
}

export default App;
