//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, FC } from 'react';
//import { SaleContext } from '../Contexts/SaleContext';
import { LocaleContext } from '../Contexts/LocaleContext';
import { currencyFormat, serverUrlGlobal } from '../tools';
import { MiniProduct } from '../Declarations';
import { useHistory } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';
import { ProductImage, RecordImage } from '../misc';

const LiteCart: FC<{ parent?: MiniProduct, packages?: MiniProduct[], onCancel: () => void }> = (props) => {
    //const { state } = useContext(SaleContext);
    const history = useHistory();
    const app = useContext(LocaleContext)
    const addedCount = props.packages!.length + 1
    const totalAdded = (props.packages?.length ? props.packages.map(x => x.SiteRealPrice!).reduce((a, c) => a + c) : 0) + (props.parent?.SiteRealPrice || 0);
    return <ConfigProvider direction={document.dir as 'ltr' | 'rtl'}>
        <Modal style={{ textAlign: document.dir == 'ltr' ? 'left' : 'right' }}
            bodyStyle={{ backgroundColor: 'rgba(217,182,138,0.3)' }}
            visible={true}
            title={<span><i className="fal fa-check-circle" aria-hidden={true} style={{ font: '30px FontAwesome' }}></i> {addedCount + ' ' + app?.localized(addedCount > 1 ? 'Items added to cart' : 'Item added to cart')}</span>}
            onCancel={props.onCancel}
            width={600}
            footer={<div>
                <button style={{ borderRadius: 2, background: '#4c1518', color: 'white', padding: '5px' }}
                    onClick={e => {
                        e.preventDefault();
                        history.push('/ShoppingCart')
                    }} >{app?.localized('ViewCart')}</button>
                <button style={{ borderRadius: 2, background: '#4c1518', color: 'white', padding: '5px' }}
                    onClick={e => {
                        e.preventDefault();
                        history.push('/Purchase')
                    }}>{app?.localized('Checkout')}</button>
            </div>}>
            {props.parent && <div style={{ padding: '0 30px' }}>

                <div style={{ textAlign: 'center' }}>
                    <ProductImage id={props.parent.ID} name={props.parent.Name!} style={{ height: 80 }} />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex', color: '#4c1518', fontSize: 20 }}>
                    <span>{props.parent.Name}</span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(props.parent.SiteRealPrice)}</span>
                </div>
                {props.packages!.map(q => <div key={q.Name}
                    style={{ justifyContent: 'space-between', display: 'flex', color: '#b9792a', fontSize: 18 }}>
                    <span>{q.Name}</span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(q.SiteRealPrice)}</span>
                </div>)}
                <div style={{ borderTop: '1px solid #b9792a', justifyContent: 'space-between', display: 'flex', color: '#4c1518', fontSize: 20, paddingBottom: 25 }}>
                    <span>{app?.localized('Total')}</span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(totalAdded)}</span>
                </div>


                <div style={{ background: 'rgba(217,182,138,0.3)', color: '#4c1518', fontSize: '20px', fontWeight: 800 }}>
                    {app?.localized('TotalInCart')} ({app?.data?.Sale?.ProductCount} {app?.localized('Items')})
                </div>

                <div style={{ justifyContent: 'space-between', display: 'flex', color: '#4c1518', fontSize: 20 }}>
                    <span>{app?.localized('SubTotal')}</span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(app?.data?.Sale?.ProductTotal)}</span>
                </div>

                <div style={{ borderTop: '1px solid #b9792a', justifyContent: 'space-between', display: 'flex', color: '#4c1518', fontSize: 20 }}>
                    <span>{app?.localized('Shipping')}
                        <span style={{ fontSize: '15px' }}>  ({app?.data?.Sale?.ShipTypeName ? app.data?.Sale.ShipTypeName : 'No shipping was chosen'})</span>
                    </span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(app?.data?.Sale?.ShipCharge)}</span>
                </div>


                <div style={{ borderTop: '1px solid #b9792a', justifyContent: 'space-between', display: 'flex', color: '#4c1518', fontSize: 20 }}>
                    <span>{app?.localized('Total')}</span>
                    <span style={{ fontWeight: 'bold' }}>{currencyFormat(app?.data?.Sale?.GrandTotal)}</span>
                </div>

            </div>}
        </Modal>
    </ConfigProvider>
}
export default LiteCart;